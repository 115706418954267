<template>
    <div class="flex flex-col gap-4 p-4">
        <div class="flex gap-2">
            <span class="text-xl font-bold">{{ meeting.name }}</span>
            <span class="text-lg">{{ meeting.date | humanizeTime }}</span>
        </div>
        <div class="shadow-md border p-4">
            <table>
                <tr v-if="meeting.location">
                    <th role="rowheader" class="p-2 text-right">{{ $t('project.follow.meetings.location') }} :</th>
                    <td class="p-2">{{ meeting.location }}</td>
                </tr>
                <tr v-if="meeting.url && meeting.url.length > 0">
                    <th role="rowheader" class="p-2 text-right">{{ $t('project.follow.meetings.report') }} :</th>
                    <td class="p-2">
                        <app-file-link
                            class="text-xs mr-4"
                            :fileName="meeting.fileName"
                            :url="meeting.url"
                        ></app-file-link>
                    </td>
                </tr>
                <tr v-if="meeting.convocations && meeting.convocations.length > 0">
                    <th role="rowheader" class="p-2 text-right align-top">
                        {{ $t('project.follow.meetings.convocations') }} :
                    </th>
                    <td class="p-2">
                        <ul>
                            <li
                                v-for="convocation in meeting.convocations"
                                :key="convocation.id"
                                class="border p-2 flex flex-col"
                            >
                                <div class="flex gap-4 justify-start">
                                    <span>{{ convocation.convocationDate | humanizeHour }}</span>
                                    <app-bundle :bundle="convocation.bundle" />
                                </div>
                            </li>
                        </ul>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import { getMeeting } from './meetings.service';
import AppBundle from '@/components/app-bundle/appBundle';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import { combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getMapById } from '@/services/sanitize.service';
export default {
    components: {
        AppFileLink,
        AppBundle,
    },
    props: {
        meetingId: String,
        showReportLink: { type: Boolean, default: true },
    },
    watch: {
        meetingId() {
            this.init();
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getMeeting(this.$route.params.projectId, this.meetingId),
                    getBundles(this.$route.params.projectId),
                ]).subscribe(([meeting, bundles]) => {
                    this.bundles = bundles;
                    const bundleMap = getMapById(bundles);
                    if (meeting) {
                        this.meeting = {
                            ...meeting,
                            convocations: meeting.convocations.map((convocation) => ({
                                ...convocation,
                                bundle: bundleMap[convocation.bundleId],
                            })),
                        };
                        this.lastHour = this.meeting.date;
                        this.selectedConvocation = this.meeting.convocations.map((convocation) => convocation.bundle);
                    }
                }),
            ];
        },
    },
    data() {
        return {
            subscriptions: [],
            meeting: { convocations: [] },
        };
    },
};
</script>
