<template>
    <table-gantt
        ref="gantt"
        :tasks="filteredTasks"
        :options="options"
        class="mx-1 text-2xs"
        :agenda="agenda"
        :minDate="options.minDate"
        :maxDate="options.maxDate"
    ></table-gantt>
</template>

<script>
import { filterTasks, populateTree } from './planning.service';
import TableGantt from '@/components/gantt/TableGantt';
import { excludeOutOfDateRangeTasks, getWeekEnds, mapAgenda, mapTasks } from '@/components/gantt/ganttService';
import startOfDay from 'date-fns/startOfDay';
import { getPlannedTasks } from '@/features/tasks/plannedTasks.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { getDirectories } from '@/features/services/directories.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getLastReference } from '@/features/planning/references/references.service';
import { queryLastPlanningExport } from '@/features/planning/planning/planningExport.service';
import { sortBy } from '@/services/sanitize.service';

export default {
    components: {
        TableGantt,
    },
    async created() {
        const planningExport = await queryLastPlanningExport(this.$route.params.projectId);
        const refDate = startOfDay(planningExport.refDate || new Date());
        this.subscriptions = [
            combineLatest([
                getDirectories(this.$route.params.projectId),
                getBundles(this.$route.params.projectId),
                getCalendar(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getLastReference(this.$route.params.projectId),
                getPlannedTasks(this.$route.params.projectId, new BehaviorSubject(refDate)),
            ]).subscribe(([directories, bundles, agenda, folders, lastReference, tasks]) => {
                this.agenda = mapAgenda(
                    sortBy([...getWeekEnds(planningExport.minDate, planningExport.maxDate), ...agenda], 'time'),
                    this.options.refDate,
                );
                this.directories = directories;
                this.folders = folders;
                this.lastReference = lastReference;
                this.loading = false;
                this.options = {
                    print: true,
                    scale: planningExport.scale,
                    minDate: new Date(planningExport.minDate),
                    maxDate: new Date(planningExport.maxDate),
                    showCollapseButtons: false,
                    showLineNumbers: true,
                    showProgressLine: planningExport.showProgressLine,
                    showReferenceData: planningExport.showReferenceData,
                    showPlannedData: planningExport.showPlannedData,
                    showReference: planningExport.showReference,
                    refDate: refDate,
                    showReal: planningExport.showReal,
                    progressReportedTo: planningExport.progressReportedTo,
                    showPlanned: planningExport.showPlanned,
                    showLateColumn: planningExport.showLateColumn,
                    showQuantityColumn: planningExport.showQuantityColumn,
                    showDurationColumn: planningExport.showDurationColumn,
                    showProgressColumn: planningExport.showProgressColumn,
                    showRealData: planningExport.showRealData,
                    sortKey: planningExport.sortKey,
                };
                tasks = filterTasks(tasks, planningExport.filter || []);
                if (!planningExport.showOutOfRangeLines) {
                    tasks = excludeOutOfDateRangeTasks(tasks, planningExport.minDate, planningExport.maxDate);
                }
                const maxLevel = planningExport.expandToLevel || 99;
                let isCollapsed = false;
                let lastCollapsedLevel = 0;
                tasks = mapTasks(
                    populateTree(
                        tasks,
                        lastReference,
                        bundles,
                        folders,
                        directories,
                        planningExport.structure,
                        agenda,
                        refDate,
                        planningExport.sortKey,
                    ),
                ).reduce((acc, task) => {
                    if (isCollapsed && lastCollapsedLevel >= task.level) {
                        isCollapsed = false;
                    }
                    if (task.level < maxLevel && !isCollapsed) {
                        if (planningExport.collapsed.includes(task.id)) {
                            isCollapsed = true;
                            task.collapsed = true;
                            lastCollapsedLevel = task.level;
                        }
                        return [...acc, task];
                    } else {
                        return acc;
                    }
                }, []);
                this.filteredTasks = tasks;
            }),
        ];
    },
    data() {
        return {
            agenda: [],
            subscriptions: [],
            filteredTasks: [],
            options: {
                print: true,
                scale: 'months',
                minDate: startOfDay(new Date()),
                maxDate: new Date(),
                showCollapseButtons: false,
                showLineNumbers: false,
                refDate: new Date(),
                showProgressLine: false,
                showReferenceData: false,
                showPlannedData: false,
                showReference: false,
                progressReportedTo: 'reference',
                showReal: true,
                showPlanned: true,
                showLateColumn: false,
                showQuantityColumn: false,
                showDurationColumn: false,
                showProgressColumn: false,
                showRealData: false,
            },
        };
    },
};
</script>
