<template>
    <ValidationObserver v-slot="{ invalid, dirty }" class="h-full w-full block" ref="observer">
        <form class="p-2 flex text-sm gap-4">
            <table class="border">
                <thead>
                    <tr>
                        <th class="border p-1"></th>
                        <th class="border p-1">{{ $t('project.follow.planning.startDate') }}</th>
                        <th class="border p-1">{{ $t('project.follow.planning.endDate') }}</th>
                        <th class="border p-1">{{ $t('project.follow.planning.duration') }}</th>
                        <th class="border p-1">{{ $t('project.follow.planning.progress') }}</th>
                        <th class="border p-1">{{ $t('project.follow.planning.late') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="border p-1 text-right align-top" role="rowheader">
                            {{ $t('project.follow.planning.plannedDates') }}
                        </th>
                        <td class="border p-1">
                            <div class="flex items-center flex-col">
                                <app-date-link
                                    :label="$t('project.follow.planning.plannedStartDate') + ' : '"
                                    :disabled="readOnly || isStartDateComputed"
                                    v-model="task.plannedStartDate"
                                    :show-label="false"
                                    @input="save"
                                ></app-date-link>
                                <app-tips class="text-xs ml-1" :class="{ 'opacity-0': XSPredecessors.length === 0 }">
                                    <span v-if="XSPredecessors.length > 1">
                                        {{ XSPredecessors.length }} {{ $t('project.follow.planning.somePredecessors') }}
                                    </span>
                                    <span v-else-if="XSPredecessors.length === 1">
                                        {{ XSPredecessors.length }} {{ $t('project.follow.planning.onePredecessor') }}
                                    </span>
                                    <span v-else>0 {{ $t('project.follow.planning.onePredecessor') }}</span>
                                </app-tips>
                            </div>
                        </td>
                        <td class="border p-1">
                            <div class="flex items-center flex-col">
                                <span>{{ task.plannedEndDate | humanizeDate }}</span>
                                <app-tips class="text-xs ml-1" :class="{ 'opacity-0': XSPredecessors.length === 0 }">
                                    <span v-if="EEPredecessors.length > 1">
                                        {{ EEPredecessors.length }} {{ $t('project.follow.planning.somePredecessors') }}
                                    </span>
                                    <span v-else-if="EEPredecessors.length === 1">
                                        {{ EEPredecessors.length }} {{ $t('project.follow.planning.onePredecessor') }}
                                    </span>
                                    <span v-else>0 {{ $t('project.follow.planning.onePredecessor') }}</span>
                                </app-tips>
                            </div>
                        </td>
                        <td class="border p-1">
                            <div class="flex items-center flex-col gap-1">
                                <app-duration-link
                                    v-model="task.plannedDuration"
                                    :label="$t('project.follow.planning.plannedDuration') + ' :'"
                                    :agenda="agenda"
                                    :reference-date="isDurationBackward ? task.plannedEndDate : task.plannedStartDate"
                                    :backward="isDurationBackward"
                                    :bundle-id="task.bundleId"
                                    @input="save"
                                    :disabled="readOnly"
                                    :show-label="false"
                                />
                                <app-duration-link
                                    v-model="task.plannedDuration"
                                    :agenda="agenda"
                                    variant="formula"
                                    :reference-date="isDurationBackward ? task.plannedEndDate : task.plannedStartDate"
                                    :backward="isDurationBackward"
                                    :bundle-id="task.bundleId"
                                    @input="save"
                                    :show-label="false"
                                    :disabled="readOnly"
                                    class="ml-1"
                                />
                            </div>
                        </td>
                        <td class="border p-1 text-center">
                            <span>{{ task.expectedProgress ? task.expectedProgress : 0 }}%</span>
                        </td>
                        <td class="border p-1 text-center">
                            <div
                                class="flex gap-1 justify-center"
                                :title="$t('project.follow.planning.relativeLateTips')"
                            >
                                <span class="font-bold text-red-700" v-if="task.late > 0">J+{{ task.late }}</span>
                                <span class="font-bold text-green-600" v-else-if="task.late === 0">0</span>
                                <span class="font-bold text-green-600" v-else-if="task.late <= 0">
                                    J{{ task.late }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="border p-1 text-right" role="rowheader">
                            {{ $t('project.follow.planning.realDates') }}
                        </th>
                        <td class="border p-1 text-center">
                            <app-date-link
                                class="justify-center"
                                :disabled="readOnly"
                                :label="$t('project.follow.planning.realStartDate') + ' :'"
                                v-model="task.realStartDate"
                                :show-label="false"
                                @input="save"
                                @keypress.enter.prevent="save"
                            ></app-date-link>
                        </td>
                        <td class="border p-1">
                            <div class="flex justify-center flex-col">
                                <app-date-link
                                    class="gap-3 justify-center"
                                    :disabled="readOnly"
                                    :label="$t('project.follow.planning.realEndDate') + ' :'"
                                    v-model="task.realEndDate"
                                    @input="save"
                                    :show-label="false"
                                    @keypress.enter.prevent="save"
                                ></app-date-link>
                                <app-tips v-if="!task.realEndDate" class="ml-1">
                                    {{ $t('project.follow.planning.estimatedEndDateTips')
                                    }}{{ task.estimatedEndDate | humanizeDate }}
                                </app-tips>
                            </div>
                        </td>
                        <td class="border p-1 text-center">
                            <template v-if="!task.realEndDate">
                                <app-number-link
                                    v-model="task.realDuration"
                                    :label="$t('project.follow.planning.realDuration') + ' :'"
                                    @input="save"
                                    :show-label="false"
                                    class="justify-center"
                                    :disabled="readOnly"
                                >
                                    <div :class="{ 'text-gray-500 italic': !task.realDuration, underline: !readOnly }">
                                        {{ task.realDuration || '...' }}
                                    </div>
                                </app-number-link>
                                <app-tips v-if="!task.realEndDate" class="ml-1">
                                    {{
                                        !task.realDuration
                                            ? $t('project.follow.planning.estimatedDurationTips') +
                                              ' ' +
                                              task.estimatedRealDuration
                                            : ''
                                    }}
                                </app-tips>
                            </template>
                            <span v-else>
                                {{ task.estimatedRealDuration }}
                            </span>
                        </td>
                        <td class="border p-1">
                            <app-number-link
                                v-model="task.realProgress"
                                :label="$t('project.follow.planning.realProgress') + ' :'"
                                @input="saveProgress"
                                :show-label="false"
                                class="justify-center"
                                :disabled="!!task.realEndDate || readOnly"
                            >
                                <div :class="{ underline: !readOnly && !task.realEndDate }">
                                    {{ (task.realProgress || '0') + '%' }}
                                </div>
                            </app-number-link>
                        </td>
                        <td class="border p-1">
                            <div
                                class="flex gap-1 justify-center"
                                :title="$t('project.follow.planning.criticalLateTips')"
                            >
                                <span class="font-bold text-red-700" v-if="task.criticalLate > 0">
                                    J+{{ task.criticalLate }}
                                </span>
                                <span v-else-if="task.criticalLate === 0" class="font-bold text-green-600">0</span>
                                <span v-else-if="task.criticalLate <= 0" class="font-bold text-green-600">
                                    J{{ task.criticalLate }}
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th class="border p-1 text-right" role="rowheader">
                            {{ $t('project.follow.planning.referenceDates') }}
                        </th>
                        <td class="border p-1 text-center">
                            <span class="" v-if="task.referenceStartDate">
                                {{ task.referenceStartDate | humanizeDate }}
                            </span>
                            <span class="" v-else>-</span>
                        </td>
                        <td class="border p-1 text-center">
                            <span v-if="task.referenceEndDate">
                                {{ task.referenceEndDate | humanizeDate }}
                            </span>
                            <span v-else>-</span>
                        </td>
                        <td class="border p-1 text-center">
                            <span class="">
                                {{ task.referenceDuration || '-' }}
                            </span>
                        </td>
                        <td class="border p-1 text-center">
                            <span>
                                {{ task.referenceProgress ? task.referenceProgress + '%' : '-' }}
                            </span>
                        </td>
                        <td class="border p-1 text-center">
                            <div
                                class="flex gap-1 justify-center"
                                :title="$t('project.follow.planning.absoluteLateTips')"
                            >
                                <span class="font-bold text-red-700" v-if="task.referenceLate > 0">
                                    J+{{ task.referenceLate }}
                                </span>
                                <span v-else-if="task.referenceLate === 0" class="font-bold text-green-600">0</span>
                                <span v-else-if="task.referenceLate <= 0" class="font-bold text-green-600">
                                    J{{ task.referenceLate }}
                                </span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
        <app-save-on-leave :invalid="invalid" :dirty="dirty" :save-fn="save"></app-save-on-leave>
    </ValidationObserver>
</template>

<script>
import AppNumberInput from '../../../components/appNumberInput/AppNumberInput';
import AppDateInput from '../../../components/appDateInput/AppDateInput';
import AppButton from '../../../components/appButton/AppButton';
import AppLabel from '../../../components/appLabel/AppLabel';

import AppDurationInput from '../../../components/appDurationInput/AppDurationInput';
import AppSaveOnLeave from '@/components/AppSaveOnLeave';
import AppSlider from '@/components/appSlider/AppSlider';
import AppTips from '@/components/app-tips/AppTips';
import AppDateLink from '@/components/appDateLink/AppDateLink';
import AppDurationLink from '@/components/appDurationLink/AppDurationLink';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import startOfDay from 'date-fns/startOfDay';

export default {
    components: {
        AppNumberLink,
        AppDurationLink,
        AppDateLink,
        AppTips,
        AppSlider,
        AppSaveOnLeave,
        AppDurationInput,
        AppLabel,
        AppDateInput,
        AppNumberInput,
        AppButton,
    },
    props: ['task', 'agenda', 'tasks', 'readOnly'],
    computed: {
        XSPredecessors() {
            return this.task.predecessors
                ? this.task.predecessors.filter((predecessor) => predecessor.type === 'ES' || predecessor.type === 'SS')
                : [];
        },
        EEPredecessors() {
            return this.task.predecessors
                ? this.task.predecessors.filter((predecessor) => predecessor.type === 'EE')
                : [];
        },
        isStartDateComputed() {
            const hasXSPredecessors = this.task.hasXSPredecessor;
            const hasEEPredecessors = this.task.hasEEPredecessor;
            return hasXSPredecessors || (!hasXSPredecessors && hasEEPredecessors);
        },
        isDurationBackward() {
            const hasXSPredecessors = this.task.hasXSPredecessor;
            const hasEEPredecessors = this.task.hasEEPredecessor;
            return !hasXSPredecessors && hasEEPredecessors;
        },
    },
    methods: {
        saveProgress() {
            if (!this.readOnly && this.$refs.observer.flags.valid) {
                this.$refs.observer.reset();
                if (this.task.realProgress > 100) {
                    this.task.realProgress = 100;
                }
                if (this.task.realProgress === 100 && !this.task.realEndDate) {
                    this.task.realEndDate = startOfDay(new Date());
                    return this.$emit('input', {
                        id: this.task.id,
                        realProgress: this.task.realProgress,
                        realEndDate: this.task.realEndDate,
                    });
                } else {
                    return this.$emit('input', {
                        id: this.task.id,
                        realProgress: this.task.realProgress,
                    });
                }
            }
        },
        save: async function () {
            if (!this.readOnly && this.$refs.observer.flags.valid) {
                this.$refs.observer.reset();
                if (this.task.realEndDate && !this.task.realStartDate) {
                    this.task.realStartDate = this.task.realEndDate;
                }
                if (this.task.realEndDate && this.task.realProgress < 100) {
                    this.task.realProgress = 100;
                }
                return this.$emit('input', {
                    id: this.task.id,
                    plannedStartDate: this.task.plannedStartDate,
                    plannedEndDate: this.task.plannedEndDate,
                    realStartDate: this.task.realStartDate,
                    realEndDate: this.task.realEndDate,
                    plannedDuration: this.task.plannedDuration,
                    realDuration: this.task.realDuration,
                    realProgress: this.task.realProgress,
                });
            }
        },
    },
};
</script>
