<template>
    <label class="flex" @drop.prevent="dropFile" @dragover.prevent="dragover" @dragleave.prevent="dragleave">
        <a
            class="font-bold cursor-pointer flex items-center"
            :class="{
                'opacity-25': disabled,
                'px-2': icon,
                'p-2': size !== 'mini',
                'p-1': size === 'mini',
                btn: variant !== 'link',
                underline: variant === 'link',
                'bg-gray-300': isDraggingOver,
                'bg-white': !isDraggingOver,
            }"
        >
            <Icon
                :class="{ 'mx-2': size !== 'mini', 'mr-2': size === 'mini' }"
                width="16"
                height="16"
                :name="icon"
                v-if="icon"
            />
            {{ label }}
            <icon-rotate-right class="animate animate-spin" v-if="loading"></icon-rotate-right>
        </a>
        <input
            ref="input"
            type="file"
            :multiple="true"
            class="hidden"
            v-on:change="onSelect"
            :disabled="disabled"
            :name="name"
        />
    </label>
</template>
<script>
import Icon from '../../icons/Icon';
import IconRotateRight from '../../icons/IconRotateRight';
import { getAPIHeaders } from '@/services/api.service';
import { error } from '@/features/toasts/toats.service';
export default {
    components: { IconRotateRight, Icon },
    data() {
        return {
            loading: false,
            isDraggingOver: false,
        };
    },
    props: {
        method: {
            type: String,
            default: 'POST',
        },
        endPoint: String,
        resize: Number,
        name: String,
        label: String,
        size: String,
        icon: String,
        variant: String,
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        dragover() {
            this.isDraggingOver = true;
        },
        dragleave() {
            this.isDraggingOver = false;
        },
        dropFile(event) {
            this.onSelect({ target: event.dataTransfer });
            this.isDraggingOver = false;
        },
        async onSelect(event) {
            const files = event.target.files;
            const formData = new FormData();
            this.loading = true;
            try {
                for (const file of files) {
                    if (this.resize && file.type.startsWith('image/')) {
                        try {
                            formData.append(
                                'files',
                                await this.imageBlobReduce.toBlob(file, { max: this.resize }),
                                file.name,
                            );
                        } catch {
                            formData.append('files', file);
                        }
                    } else {
                        formData.append('files', file);
                    }
                }
                try {
                    const result = await fetch(this.endPoint, {
                        method: this.method,
                        body: formData,
                        headers: getAPIHeaders(),
                    });
                    if (result.status === 413) {
                        error(this.$t('uploads.tooLarge'));
                    }
                    if (result.ok) {
                        if (result.headers.get('Content-Type') === 'application/json') {
                            this.$emit('loaded', await result.json());
                        } else {
                            this.$emit('loaded', await result.text());
                        }
                    } else {
                        this.$emit('error', await result.text());
                    }
                } catch (e) {
                    console.error(e);
                    error(this.$t('uploads.error'));
                }
            } finally {
                this.loading = false;
            }
        },
        click() {
            this.$refs.input.click();
        },
    },
};
</script>
<style scoped>
.btn {
    border-color: #e5e5e5;
    border-width: 1px;
}
.btn:disabled {
    opacity: 0.3;
}
.btn:hover:not(:disabled),
.btn:focus:not(:disabled) {
    background-color: #f0f0f0;
    border-color: #e3e3e3;
}
</style>
