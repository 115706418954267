<template>
    <AppList
        :items="meetings"
        :filterFn="filterFn"
        :filterOptions="filterOptions"
        @navigateTo="navigateTo"
        @newItem="addMeeting()"
        :removable="false"
        class="max-w-5xl"
        :new-item-label="readOnly ? '' : $t('project.editions.meetingReports.new')"
        :line-height="80"
        :disabled="readOnly"
    >
        <template v-slot:headers>
            <app-checkbox v-model="hideOld" :label="$t('project.editions.meetingReports.hideOld')" />
        </template>
        <template v-slot:item="{ item }">
            <div class="w-full">
                <div class="flex text-xs text-gray-600 justify-between w-full">
                    <span class="flex gap-2">
                        <span>{{ item.date | humanizeTime }}</span>
                        <span>{{ item.type }}</span>
                    </span>
                </div>
                <div class="flex text-xs items-baseline flex-col">
                    <span class="text-lg font-bold mr-2">
                        {{ item.name }}
                    </span>
                    <div class="w-full flex justify-end">
                        <app-file-link
                            class="text-xs"
                            v-if="item.generationSucceeded && item.url"
                            :fileName="item.fileName"
                            :url="item.url"
                        ></app-file-link>
                    </div>
                </div>
            </div>
        </template>
    </AppList>
</template>

<script>
import { sortBy } from '@/services/sanitize.service';
import { createMeeting, getMeetings, initNewMeeting } from './meetings.service';
import AppList from '../../components/appList/AppList';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import { queryProject } from '@/features/projects/projects.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';

export default {
    components: { AppCheckbox, AppFileLink, AppList },
    props: {},
    created() {
        this.subscriptions = [
            getMeetings(this.$route.params.projectId).subscribe((meetings) => {
                this.meetings = sortBy(meetings, (value) => value.date.getTime());
            }),
            getCalendar(this.$route.params.projectId).subscribe((agenda) => {
                this.agenda = agenda;
            }),
        ];
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_meetingReports');
            this.project = project;
        });
    },
    methods: {
        async addMeeting() {
            const meeting = await initNewMeeting(this.project, this.agenda, this.meetings, (key) => this.$t(key));
            const result = await createMeeting(this.$route.params.projectId, meeting);
            this.$router.push({
                name: 'meetingReport',
                params: { projectId: this.$route.params.projectId, meetingId: result.id },
            });
        },
        filterFn(filter) {
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((company) => company.content);
            return this.meetings.filter((meeting) => this.matchMultiFilter(meeting, stringCriteria));
        },
        matchMultiFilter(item, stringCriteria) {
            return (
                (stringCriteria.length === 0 ||
                    stringCriteria.find((criteria) => (item.date.toISOString() + '' + item.name).includes(criteria))) &&
                ((item.date && new Date().getTime() - item.date.getTime() < 8 * 24 * 3600000) || !this.hideOld)
            );
        },
        navigateTo: function (meeting) {
            this.$router.push({
                name: 'meetingReport',
                params: { projectId: this.$route.params.projectId, meetingId: meeting.id },
            });
        },
    },
    data() {
        return {
            subscriptions: [],
            readOnly: true,
            hideOld: true,
            project: null,
            meetings: [],
            filterOptions: [],
            filter: '',
        };
    },
};
</script>
