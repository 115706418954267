<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button
                    @click="addObservation()"
                    :label="$t('observations.newSynthesisObservation')"
                    v-if="!readOnly"
                />
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.name }}</span>
                    <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
                </template>
            </app-multi-picker>

            <div class="flex justify-between">
                <div>
                    <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="resolve" v-if="validationAllowed">{{ $t('commons.actionResolve') }}</option>
                        <option value="validate" v-if="validationAllowed">{{ $t('commons.actionValidate') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                    </app-select>
                </div>
                <div class="w-full flex flex-col justify-start gap-1 sm:gap-4 sm:justify-center sm:flex-row text-sm">
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        labelClass="text-observations-to-fix-late font-bold"
                        v-model="pending"
                        :label="$t('observations.toFix') + (pending ? ' (' + pendingCount + ')' : '')"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        labelClass="text-observations-done font-bold"
                        v-model="resolved"
                        :label="$t('observations.done') + (resolved ? ' (' + resolvedCount + ')' : '')"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="validated"
                        labelClass="text-observations-validated font-bold"
                        :label="$t('observations.validated') + (validated ? ' (' + validatedCount + ')' : '')"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="obsolete"
                        :label="$t('project.follow.follow.obsolete') + (obsolete ? ' (' + obsoleteCount + ')' : '')"
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky z-10 top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 3.5rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('index')"
                            >
                                {{ '#' }}
                                <div v-if="sortKey === 'index'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <span>{{ $t('project.follow.observation.titleLabel') }}</span>
                        </th>
                        <th style="" class="text-left border-r p-1 hidden sm:table-cell">
                            {{ $t('project.follow.observation.recipients') }}
                        </th>
                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('resolvedAt')"
                            >
                                {{ $t('project.follow.observation.resolvedAt') }}
                                <div v-if="sortKey === 'resolvedAt'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="observation in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + observation.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="observation.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(observation)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                <div
                                    class="p-1 px-2 border flex justify-center"
                                    :class="{
                                        [getObservationClass(observation)]: true,
                                    }"
                                >
                                    {{ (observation.index + 1).toString().padStart(3, '0') }}
                                </div>
                            </td>
                            <td class="border-r p-1">
                                <router-link
                                    class="hover:underline"
                                    :to="{
                                        name: 'followSynthesis',
                                        params: {
                                            ...$route.params,
                                            observationId: observation.id,
                                        },
                                    }"
                                >
                                    <app-clamp :htmlContent="observation.title" :number-of-lines="2" />
                                    <span v-if="!observation.title || observation.title.trim().length === 0">...</span>
                                </router-link>
                            </td>
                            <td class="border-r p-1 truncate hidden sm:table-cell">
                                <div
                                    v-if="observation.recipients.length === 1"
                                    style="max-width: 10rem"
                                    class="truncate"
                                >
                                    <span class="">
                                        {{
                                            observation.recipients[0].reference
                                                ? '#' +
                                                  observation.recipients[0].reference +
                                                  ' ' +
                                                  observation.recipients[0].name
                                                : observation.recipients[0].name
                                        }}
                                    </span>
                                </div>
                                <div class="flex flex-col w-full" v-if="observation.recipients.length === 2">
                                    <span>
                                        {{
                                            (observation.recipients[0].reference || '') +
                                            ' ' +
                                            observation.recipients[0].name
                                        }}
                                    </span>
                                    <span class="">
                                        {{
                                            (observation.recipients[1].reference || '') +
                                            ' ' +
                                            observation.recipients[1].name
                                        }}
                                    </span>
                                </div>
                                <span
                                    v-if="observation.recipients.length >= 3"
                                    :title="
                                        observation.recipients
                                            .map((recipient) => (recipient.reference || '') + ' ' + recipient.name)
                                            .join('\n')
                                    "
                                >
                                    {{ observation.recipients.map((recipient) => recipient.reference || '').join() }}
                                </span>
                            </td>
                            <td class="text-center border-r p-1 hidden sm:table-cell">
                                <app-date-link
                                    @enter="focusToNextDate(observation)"
                                    :ref="'date_' + observation.id"
                                    :disabled="readOnly || observation.validatedAt"
                                    :label="$t('project.follow.observation.resolvedAt')"
                                    :show-label="false"
                                    v-model="observation.resolvedAt"
                                    @input="updateDate(observation, $event)"
                                ></app-date-link>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>
import AppCheckbox from '../../components/app-checkbox/AppCheckbox';
import { filterMatch, sortBy } from '@/services/sanitize.service';
import AppList from '../../components/appList/AppList';
import AppSelect from '@/components/appSelect/AppSelect';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import AppButton from '@/components/appButton/AppButton';
import {
    createObservation,
    getAllObservationsSyntheses,
    removeObservation,
    updateObservation,
} from '../observations/observation.service';

import AppDateLink from '@/components/appDateLink/AppDateLink';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppPopup from '@/components/app-popup/AppPopup';
import AppBundlePicker from '@/components/appBundlePicker';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppFooter from '@/components/appFooter/AppFooter';
import AppInputText from '@/components/appInputText/AppInputText';
import AppCancel from '@/components/appCancel/AppCancel';
import IconMenuDown from '@/icons/IconMenuDown';
import IconMenuUp from '@/icons/IconMenuUp';
import AppNumberInput from '@/components/appNumberInput/AppNumberInput';
import AppNumberLink from '@/components/appNumberLink/AppNumberLink';
import IconCheckCircleOutline from '@/icons/IconCheckCircleOutline';
import AppStaticMarker from '@/components/appStaticMarker/AppStaticMarker';
import { getObservationClass } from '@/features/observations/observation.service';
import AppClamp from '@/components/appClamp/AppClamp';
import AppTips from '@/components/app-tips/AppTips';
import ObservationStats from '@/features/follow/observations/ObservationStats';
import AppBundle from '@/components/app-bundle/appBundle';
import { getProject } from '@/features/projects/projects.service';
import { combineLatest } from 'rxjs';
import { getBundleMap } from '@/features/bundles/bundles.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import IconRotateRight from '@/icons/IconRotateRight.vue';
import AppTextarea from '@/components/app-textarea/AppTextarea.vue';

export default {
    components: {
        AppTextarea,
        IconRotateRight,
        AppBundle,
        ObservationStats,
        AppTips,
        AppClamp,
        AppStaticMarker,
        IconCheckCircleOutline,
        AppNumberLink,
        AppNumberInput,
        IconMenuUp,
        IconMenuDown,
        AppCancel,
        AppInputText,
        AppFooter,
        AppDateInput,
        AppBundlePicker,
        AppPopup,
        AppDateLink,
        AppButton,
        AppQuickActions,
        AppMultiPicker,
        AppSelect,
        AppList,
        AppCheckbox,
    },
    async created() {
        this.restoreFilter();
        this.restoreSort();
        this.init();
    },
    computed: {
        validatedCount() {
            return this.filteredItems.filter((item) => item.validatedAt).length;
        },
        resolvedCount() {
            return this.filteredItems.filter((item) => item.resolvedAt && !item.validatedAt).length;
        },
        obsoleteCount() {
            return this.filteredItems.filter((item) => item.obsoleteAt).length;
        },
        pendingCount() {
            return this.filteredItems.filter((item) => !item.resolvedAt).length;
        },
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'resolvedAt') {
                        return item.resolvedAt;
                    } else {
                        return item.index;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.bundleCriteriaType'),
                    id: this.$t('commons.bundleCriteriaType'),
                    children: this.bundles.map((bundle, index) => ({
                        ...bundle,
                        name: bundle.label,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.bundleCriteriaType'),
                        _isBundleCriteria: true,
                    })),
                },
            ];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                getCalendar(this.$route.params.projectId).subscribe((calendar) => {
                    this.calendar = calendar;
                }),
                combineLatest([
                    getAllObservationsSyntheses(this.$route.params.projectId),
                    getBundleMap(this.$route.params.projectId),
                    getProject(this.$route.params.projectId),
                ]).subscribe(async ([observations, bundleMap, project]) => {
                    this.readOnly = !project.me.allowedFeatures.includes('project_observations');
                    this.validationAllowed = project.me.allowedFeatures.includes('project_observations_MOEValidation');
                    this.projectPhase = project.phase;
                    this.me = project.me;
                    this.bundles = sortBy(Object.values(bundleMap), (bundle) => `${bundle.reference}${bundle.name}`);
                    this.restoreSelection();
                    this.items = observations.map((observation) => {
                        const recipients = observation.recipientIds.map((id) => bundleMap[id]);
                        return {
                            ...observation,
                            recipients,
                            filterString: [
                                observation.index + 1,
                                observation.title,
                                recipients.map((recipient) => recipient.reference + ' ' + recipient.name).join(),
                            ].join(),
                            isSelected: this.selection.includes(observation.id),
                        };
                    });
                    this.cleanupSavedSelection();

                    const lastVisitedObservationId = localStorage.getItem(
                        'syntheses.lastVisitedObservationId.' + this.$route.params.projectId,
                    );
                    if (lastVisitedObservationId) {
                        this.$nextTick(() => {
                            const element = this.$el.querySelector('#uuid_' + lastVisitedObservationId);
                            if (element) element.scrollIntoView();
                        });
                    }
                    this.loading = false;
                    this.$nextTick(async () => {
                        if (this.$route.query.forcePushObservations && !this.forcedOnce) {
                            const observations = this.items.filter(
                                (item) => new Date(item.updatedAt).getTime() > new Date().getTime() - 10 * 3600000 * 24,
                            );
                            if (await confirm("Forcer l'envoi au serveur (" + observations.length + ')')) {
                                this.forcedOnce = true;
                                await Promise.all(
                                    observations.map((observation) =>
                                        updateObservation(this.$route.params.projectId, {
                                            id: observation.id,
                                        }),
                                    ),
                                );
                            }
                        }
                    });
                }),
            ];
        },

        async addObservation() {
            const observation = await createObservation(this.$route.params.projectId, {
                title: '',
                phase: this.projectPhase,
                reportedBy: this.me.bundleIds[0],
                type: 'synthesis',
            });
            await this.$router.push({
                name: 'followSynthesis',
                params: {
                    projectId: this.$route.params.projectId,
                    observationId: observation.id,
                },
            });
        },
        getObservationClass,
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.saveSort();
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeObservation(this.$route.params.projectId, item.id)),
                    );
                    this.selection = [];
                }
            } else if (action === 'resolve') {
                if (
                    await confirm(this.$t('observations.confirmValidateMessageAll', { number: this.selection.length }))
                ) {
                    await Promise.all(
                        selectedItems.map((item) =>
                            updateObservation(this.$route.params.projectId, {
                                id: item.id,
                                resolvedAt: item.resolvedAt || new Date(),
                                resolvedBy: item.resolvedBy || this.me.bundleIds[0],
                                resolvedOnPhase: item.resolvedOnPhase || item.phase || this.projectPhase,
                            }),
                        ),
                    );
                }
            } else if (action === 'validate') {
                if (
                    await confirm(this.$t('observations.confirmValidateMessageAll', { number: this.selection.length }))
                ) {
                    await Promise.all(
                        selectedItems.map((item) =>
                            updateObservation(this.$route.params.projectId, {
                                id: item.id,
                                resolvedAt: item.resolvedAt || new Date(),
                                resolvedBy: item.resolvedBy || this.me.bundleIds[0],
                                resolvedOnPhase: item.resolvedOnPhase || item.phase || this.projectPhase,
                                validatedAt: item.validatedAt || new Date(),
                                validatedBy: item.validatedBy || this.me.bundleIds[0],
                                validatedOnPhase: item.validatedOnPhase || item.phase || this.projectPhase,
                            }),
                        ),
                    );
                }
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem('syntheses.selection.' + this.$route.params.projectId, JSON.stringify(this.selection));
        },
        restoreSelection() {
            const cache = localStorage.getItem('syntheses.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        matchBundleFilter(item, bundleIds) {
            return item.recipientIds && item.recipientIds.find((id) => bundleIds.includes(id));
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString || item.title, criteria, true));
        },
        matchStatusFilter(item) {
            const isResolved = item.resolvedAt && !item.validatedAt;
            const isObsolete = !!item.obsoleteAt;
            const isValidated = !!item.validatedAt;
            const isPending = !item.resolvedAt;
            return (
                isObsolete === this.obsolete &&
                ((isResolved && this.resolved) || (isValidated && this.validated) || (isPending && this.pending))
            );
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'syntheses_filter_' + this.$route.params.projectId,
                JSON.stringify({
                    filterValue,
                    obsolete: this.obsolete,
                    resolved: this.resolved,
                    validated: this.validated,
                    pending: this.pending,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('syntheses_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
                this.obsolete = !!parsedCache.obsolete;
                this.resolved = !!parsedCache.resolved;
                this.validated = !!parsedCache.validated;
                this.pending = !!parsedCache.pending;
            }
        },
        saveSort() {
            localStorage.setItem(
                'syntheses_sort_' + this.$route.params.projectId,
                JSON.stringify({
                    sortKey: this.sortKey,
                    sortAsc: this.sortAsc,
                }),
            );
        },
        restoreSort() {
            const cache = localStorage.getItem('syntheses_sort_' + this.$route.params.projectId);
            if (cache) {
                const sortCache = JSON.parse(cache);
                this.sortKey = sortCache.sortKey || 'index';
                this.sortAsc = sortCache.sortAsc !== false;
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const bundleIds = filter.filter((aCriteria) => aCriteria._isBundleCriteria).map((bundle) => bundle.id);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchBundleFilter: bundleIds.length === 0 || this.matchBundleFilter(item, bundleIds),
                    matchStatusFilter: this.matchStatusFilter(item),
                    matchString: this.matchString(stringCriteria, item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        updateDate(observation, date) {
            if (observation.dueDate) {
                updateObservation(this.$route.params.projectId, { id: observation.id, resolvedAt: date });
            } else {
                updateObservation(this.$route.params.projectId, {
                    id: observation.id,
                    dueDate: date,
                    resolvedAt: date,
                });
            }
        },
        focusToNextDate(observation) {
            let index = this.filteredItems.findIndex((aObservation) => aObservation.id === observation.id);
            let nextObservation = this.filteredItems[index + 1];
            while (!nextObservation && index < this.filteredItems.length) {
                nextObservation = this.filteredItems[++index];
            }
            if (nextObservation) {
                this.$emit('select', nextObservation);
                const element = this.$refs['date_' + nextObservation.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
    },
    data() {
        return {
            forcedOnce: false,
            bundles: [],
            subscriptions: [],
            loading: true,
            validationAllowed: false,
            sortKey: 'name',
            sortAsc: true,
            readOnly: true,
            selection: [],
            projectPhase: null,
            items: [],
            resolved: false,
            validated: true,
            pending: false,
            obsolete: false,
            filterValue: [],
        };
    },
};
</script>
