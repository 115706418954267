<template>
    <main v-if="!readOnly" class="max-w-screen-2xl w-full p-2 max-h-main overflow-hidden flex flex-col">
        <p class="justify-center md:justify-start flex-grow text-lg font-bold flex" v-if="!editingName">
            <app-button
                variant="borderless"
                :label="meeting.name"
                @click="editName()"
                class="border-0 underline font-bold"
            ></app-button>
        </p>
        <app-input-text
            v-model="meeting.name"
            ref="input"
            @input="saveName"
            @blur="blurSaveName"
            v-if="editingName"
        ></app-input-text>
        <div class="flex items-center w-full flex-col md:flex-row gap-2 mb-2">
            <div
                class="text-sm text-gray-700 flex-grow whitespace-nowrap flex gap-1 items-center flex-wrap justify-center md:justify-start"
            >
                <app-date-link v-model="meeting.date" @input="saveDate()"></app-date-link>
                <app-time-link v-model="meeting.date" @input="saveDate()"></app-time-link>
                <div class="underline">
                    <app-button
                        variant="borderless"
                        :label="meeting.location"
                        @click="editLocation()"
                        class="border-0 underline"
                        v-if="!editingLocation"
                    ></app-button>
                    <app-input-text
                        v-model="meeting.location"
                        ref="inputLocation"
                        @input="saveLocation"
                        @blur="blurSaveLocation"
                        v-if="editingLocation"
                    ></app-input-text>
                </div>
            </div>
            <div class="flex flex-grow justify-end flex-row gap-2">
                <app-file-link
                    class="text-xs mr-4"
                    v-if="meeting.generationSucceeded"
                    :fileName="meeting.fileName"
                    :url="meeting.url"
                    :removable="false"
                ></app-file-link>
                <div
                    v-if="meeting.generationEndDate && !meeting.generationSucceeded"
                    class="text-red-500 flex items-center"
                >
                    <icon-alert-circle width="16"></icon-alert-circle>
                    <span class="text-xs italic">
                        {{ $t('project.editions.meetingReports.generationFailed') }}
                    </span>
                </div>
                <app-button
                    size="mini"
                    class="p-2 shadow"
                    @click="exportPDF()"
                    icon="icon-file-pdf-outline"
                    v-if="!meeting.generationStartDate"
                    :label="$t('commons.generatePDF')"
                />
                <div v-if="meeting.generationStartDate && !meeting.generationEndDate" class="flex items-center">
                    <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
                    <span class="text-xs italic">
                        {{ $t('project.editions.meetingReports.generationPending') }}
                    </span>
                </div>
                <app-drop-down-button
                    label="action"
                    @input="onAction"
                    :show-label="false"
                    ref="select"
                    v-if="meeting.generationEndDate"
                    size="mini"
                >
                    <option value="regenerate">{{ $t('commons.regeneratePDF') }}</option>
                    <option value="sendByMail" :disabled="!meeting.generationSucceeded">
                        {{ $t('commons.sendByMail') }}
                    </option>
                    <option value="delete">{{ $t('commons.deletePdf') }}</option>
                </app-drop-down-button>
            </div>
            <app-select class="md:hidden w-full" :value="$route.name" @input="$router.push({ name: $event })">
                <option v-for="tab in tabs" :value="tab.id">
                    {{ tab.label }}
                </option>
            </app-select>
        </div>
        <div class="flex overflow-hidden max-h-full">
            <AppTabs
                class="text-sm flex-shrink-0 hidden md:block sticky"
                :values="tabs"
                labelProp="label"
                :selected-id="$route.name"
                :vertical="true"
            />
            <div class="flex-grow max-h-full overflow-auto">
                <router-view></router-view>
            </div>
        </div>
        <meeting-report-mail-popup ref="emailPopup"></meeting-report-mail-popup>
    </main>
    <MeetingReportReadOnly v-else :meeting="meeting"></MeetingReportReadOnly>
</template>

<script>
import { getMeeting, queryMeeting, updateMeeting } from './meetings.service';
import { updateBreadCrumbs } from '@/state/state';
import AppTabs from '@/components/appTabs/AppTabs';
import AppBackButton from '@/components/appBackButton/AppBackButton';
import AppButton from '@/components/appButton/AppButton';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppSelect from '@/components/appSelect/AppSelect';
import IconEmailOutline from '@/icons/IconEmailOutline';
import IconEmailDownloadOutline from '@/icons/IconEmailDownloadOutline';
import { getAPIHeaders } from '@/services/api.service';
import AppDropDownButton from '@/components/appDropDownButton/AppDropDownButton';
import { getProject } from '@/features/projects/projects.service';
import { getBundles } from '@/features/bundles/bundles.service';
import { combineLatest } from 'rxjs';
import { getContactMap } from '@/features/contacts/contacts.service';
import { getMapById } from '@/services/sanitize.service';
import MeetingReportMailPopup from '@/features/meetings/MeetingReportMailPopup.vue';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import IconPencil from '@/icons/IconPencil.vue';
import AppDateLink from '@/components/appDateLink/AppDateLink.vue';
import AppTimeInput from '@/components/appTimeInput/AppTimeInput.vue';
import AppTimeLink from '@/components/appTimeLink/AppTimeLink.vue';
import { error } from '@/features/toasts/toats.service';
import MeetingReportReadOnly from '@/features/meetings/MeetingReportReadOnly.vue';

export default {
    components: {
        MeetingReportReadOnly,
        AppTimeLink,
        AppTimeInput,
        AppDateLink,
        IconPencil,
        AppInputText,
        MeetingReportMailPopup,
        AppDropDownButton,
        IconEmailDownloadOutline,
        IconEmailOutline,
        AppSelect,
        AppFileLink,
        AppButton,
        AppTabs,
        AppBackButton,
    },
    async created() {
        this.subscriptions = [
            combineLatest([
                getMeeting(this.$route.params.projectId, this.$route.params.meetingId),
                getBundles(this.$route.params.projectId),
                getContactMap(this.$route.params.projectId),
                getProject(this.$route.params.projectId),
            ]).subscribe(async ([meeting, bundles, contactMap, project]) => {
                const bundleMap = getMapById(
                    bundles.map((bundle) => ({
                        ...bundle,
                        contacts: bundle.contactIds.map((contactId) => contactMap[contactId]).filter((a) => !!a),
                        responsible: bundle.responsibleId ? contactMap[bundle.responsibleId] : null,
                    })),
                );
                let nextMeeting = null;
                if (meeting.nextMeetingId) {
                    nextMeeting = await queryMeeting(this.$route.params.projectId, meeting.nextMeetingId);
                    if (nextMeeting) {
                        nextMeeting.convocations = nextMeeting.convocations.map((convocation) => ({
                            ...convocation,
                            bundle: bundleMap[convocation.bundleId],
                        }));
                    }
                }
                this.meeting = { ...meeting, nextMeeting, recipients: meeting.recipientIds.map((id) => bundleMap[id]) };
                this.project = project;
                this.readOnly =
                    (meeting.type === 'MOE' && !project.me.allowedFeatures.includes('project_meetingReportsMOE')) ||
                    (meeting.type === 'MOA' && !project.me.allowedFeatures.includes('project_meetingReportsMOA'));
                updateBreadCrumbs({ meetingName: this.meeting.name });
            }),
        ];
    },
    methods: {
        async editName() {
            this.editingName = true;
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },
        async editLocation() {
            this.editingLocation = true;
            this.$nextTick(() => {
                this.$refs.inputLocation.focus();
            });
        },
        async saveName() {
            await updateMeeting(this.$route.params.projectId, { id: this.meeting.id, name: this.meeting.name });
        },
        async saveDate() {
            await updateMeeting(this.$route.params.projectId, { id: this.meeting.id, date: this.meeting.date });
        },
        async saveLocation() {
            await updateMeeting(this.$route.params.projectId, { id: this.meeting.id, name: this.meeting.name });
        },
        async blurSaveName() {
            await updateMeeting(this.$route.params.projectId, { id: this.meeting.id, name: this.meeting.name });
            this.editingName = false;
        },
        async blurSaveLocation() {
            await updateMeeting(this.$route.params.projectId, { id: this.meeting.id, location: this.meeting.location });
            this.editingLocation = false;
        },
        async onAction(action) {
            if (action === 'regenerate') {
                await this.exportPDF();
            } else if (action === 'sendByMail') {
                this.$refs.emailPopup.open(this.meeting);
            } else if (action === 'delete') {
                await this.clearPDF();
            }
        },
        async clearPDF() {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                await fetch(
                    new Request(`/api/projects/${this.$route.params.projectId}/meetings/${this.meeting.id}/report`, {
                        method: 'DELETE',
                        headers: getAPIHeaders(),
                    }),
                );
            }
        },
        async exportPDF() {
            if (!this.meeting.url || (await confirm(this.$t('project.editions.confirmRegenerate')))) {
                const result = await fetch(
                    new Request(`/api/projects/${this.$route.params.projectId}/meetings/${this.meeting.id}/report`, {
                        method: 'PUT',
                        headers: getAPIHeaders(),
                    }),
                );
                if (!result.ok) {
                    error(this.$t('commons.connectionError'));
                } else {
                    this.meeting.generationStartDate = new Date();
                }
            }
        },
    },
    computed: {
        tabs() {
            let result = [
                {
                    id: 'meetingReport',
                    label: this.$t('project.editions.meetingReports.pages'),
                },
            ];
            if (this.meeting.showCoverPageWithPhoto) {
                result = [
                    ...result,
                    {
                        id: 'meetingReportCover',
                        label: this.$t('project.editions.meetingReports.cover'),
                    },
                ];
            }
            result = [
                ...result,
                {
                    id: 'meetingReportAttendance',
                    label: this.$t('project.editions.meetingReports.attendance'),
                },
            ];
            if (this.meeting.showGeneralProgress) {
                result = [
                    ...result,
                    {
                        id: 'meetingReportAttachments',
                        label: this.$t('project.editions.meetingReports.attachments'),
                    },
                ];
            }
            result = [
                ...result,
                {
                    id: 'meetingReportRecipients',
                    label: this.$t('project.editions.meetingReports.diffusion'),
                },
                {
                    id: 'meetingReportNextMeetings',
                    label: this.$t('project.editions.meetingReports.nextMeeting'),
                },
            ];
            if (this.meeting.showPlanning) {
                result = [
                    ...result,
                    {
                        id: 'meetingReportPlanning',
                        label: this.$t('project.editions.meetingReports.planning'),
                    },
                ];
            }
            result = [
                ...result,
                {
                    id: 'meetingReportBundlePages',
                    label: this.$t('project.editions.meetingReports.bundlePagesTitle'),
                },
                {
                    id: 'meetingReportVisualization',
                    label: this.$t('project.editions.meetingReports.visualization'),
                },
            ];
            return result;
        },
    },
    data() {
        return {
            date: new Date(),
            subscriptions: [],
            project: {},
            readOnly: true,
            editingName: false,
            editingLocation: false,
            meeting: {
                name: '',
                location: '',
                date: null,
                recipients: [],
            },
        };
    },
};
</script>
