<template>
    <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observerConvocation">
        <form class="grid grid-cols-2">
            <app-fieldset class="col-span-2 mt-4" :label="$t('commons.infos')">
                <app-input-text
                    :label="$t('commons.name')"
                    v-model="meeting.name"
                    class="col-span-2"
                    :required="true"
                    @blur="save"
                    :disabled="isReadOnly"
                />
                <app-select
                    :label="$t('commons.type')"
                    :disabled="disableType"
                    v-model="meeting.type"
                    class="col-span-2"
                    :required="true"
                    @input="save"
                >
                    <option id="MOE">MOE</option>
                    <option id="MOA">MOA</option>
                </app-select>
                <app-date-input
                    :label="$t('commons.date')"
                    :disabled="isReadOnly"
                    v-model="meeting.date"
                    class="col-span-2 md:col-span-1"
                    :required="true"
                    @input="meetingDateChanged"
                />
                <app-time-input
                    :label="$t('project.follow.meetings.hour')"
                    v-model="meeting.date"
                    :disabled="isReadOnly"
                    class="col-span-2 md:col-span-1"
                    :required="true"
                    @blur="meetingHourChanged"
                />
                <app-input-text
                    :label="$t('project.follow.meetings.location')"
                    v-model="meeting.location"
                    :disabled="isReadOnly"
                    class="col-span-2"
                    @blur="save"
                />
                <app-label v-if="meeting.url && meeting.url.length > 0" :label="$t('project.follow.meetings.report')">
                    <div class="flex">
                        <app-file-link
                            class="text-xs mr-4"
                            :fileName="meeting.fileName"
                            :url="meeting.url"
                        ></app-file-link>
                        <app-button
                            size="mini"
                            title="edit"
                            icon="icon-pencil"
                            v-if="!isReadOnly"
                            @click="
                                $router.push({
                                    name: 'meetingReport',
                                    params: {
                                        projectId: $route.params.projectId,
                                        meetingId: meeting.id,
                                    },
                                })
                            "
                        />
                    </div>
                </app-label>
                <div v-else-if="!isReadOnly && meeting.id">
                    <router-link
                        class="underline text-xs"
                        :to="{
                            name: 'meetingReport',
                            params: {
                                projectId: $route.params.projectId,
                                meetingId: meeting.id,
                            },
                        }"
                        v-if="showReportLink"
                    >
                        {{ $t('project.follow.meetings.editReport') }}
                    </router-link>
                </div>
            </app-fieldset>
        </form>
        <app-save-on-leave :dirty="dirty" :saveFn="() => save()"></app-save-on-leave>
        <app-fieldset class="col-span-2 mt-5" :label="$t('project.follow.meetings.convocations')">
            <div class="col-span-2 flex gap-4">
                <app-multi-picker
                    :value="selectedConvocation"
                    @check="onCheck"
                    @uncheck="onUncheck"
                    :options="bundles"
                    :disabled="isReadOnly"
                    variant="button"
                    :show-label="false"
                    :show-input="false"
                    :label="$t('project.follow.meetings.addConvocation')"
                    :title="$t('project.follow.meetings.addConvocation')"
                >
                    <template v-slot:option="{ option }">
                        <app-bundle :bundle="option" />
                    </template>
                </app-multi-picker>
            </div>
            <div class="col-span-2 flex flex-col gap-4">
                <ul class="flex-grow overflow-auto">
                    <li v-if="meeting.convocations.length === 0" class="text-center italic text-gray-700 text-sm">
                        {{ $t('commons.emptyList') }}
                    </li>
                    <li
                        v-for="convocation in meeting.convocations"
                        :v-key="convocation.id"
                        class="border p-2 flex flex-col hover:bg-gray-200 cursor-pointer"
                    >
                        <a :id="'uuid_' + convocation.id"></a>
                        <div class="flex justify-between">
                            <slot name="item">
                                <div class="w-full flex items-baseline">
                                    <app-time-input
                                        :label="$t('project.follow.meetings.convocationHour')"
                                        :show-label="false"
                                        v-model="convocation.convocationDate"
                                        :required="true"
                                        :disabled="isReadOnly"
                                        @blur="updateConvocations()"
                                    />
                                    <app-bundle :bundle="convocation.bundle" />
                                </div>
                            </slot>
                            <div class="flex items-center justify-end">
                                <app-button
                                    size="mini"
                                    @click="onUncheck([convocation.bundle])"
                                    :disabled="isReadOnly"
                                    variant="danger"
                                    aria-label="remove item"
                                    icon="icon-trash-can-outline"
                                />
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="flex justify-start">
                    <app-button
                        icon="icon-email-edit-outline"
                        :label="$t('project.follow.meetings.getConvocationMail')"
                        @click="$refs.emailPopup.open(meeting)"
                        :disabled="isReadOnly"
                    ></app-button>
                </div>
            </div>
        </app-fieldset>
        <meeting-convocations-mail-popup ref="emailPopup"></meeting-convocations-mail-popup>
    </ValidationObserver>
</template>

<script>
import AppInputText from '../../components/appInputText/AppInputText';
import AppFieldset from '../../components/appFieldset/AppFieldset';
import AppButton from '../../components/appButton/AppButton';
import AppMultiPicker from '../../components/appMultiPicker/AppMultiPicker';
import { getMeeting, updateMeeting } from './meetings.service';
import AppQuickActions from '../../components/appQuickActions/AppQuickActions';
import AppBackButton from '../../components/appBackButton/AppBackButton';
import AppSaveOnLeave from '../../components/AppSaveOnLeave';
import AppTimeInput from '@/components/appTimeInput/AppTimeInput';
import AppBundle from '@/components/app-bundle/appBundle';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import AppLabel from '@/components/appLabel/AppLabel';
import { combineLatest } from 'rxjs';
import { getBundles } from '@/features/bundles/bundles.service';
import { getMapById, sortBy } from '@/services/sanitize.service';
import IconEmailDownloadOutline from '@/icons/IconEmailDownloadOutline';
import MeetingReportMailPopup from '@/features/meetings/MeetingReportMailPopup.vue';
import MeetingConvocationsMailPopup from '@/features/meetings/MeetingConvocationsMailPopup.vue';
import AppDateInput from '@/components/appDateInput/AppDateInput.vue';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { getProject } from '@/features/projects/projects.service';

export default {
    components: {
        AppSelect,
        AppDateInput,
        MeetingConvocationsMailPopup,
        MeetingReportMailPopup,
        IconEmailDownloadOutline,
        AppLabel,
        AppFileLink,
        AppBundle,
        AppTimeInput,
        AppSaveOnLeave,
        AppBackButton,
        AppQuickActions,
        AppMultiPicker,
        AppButton,
        AppFieldset,
        AppInputText,
    },
    props: {
        meetingId: String,
        showReportLink: { type: Boolean, default: true },
    },
    watch: {
        meetingId() {
            this.init();
        },
    },
    created() {
        this.init();
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getMeeting(this.$route.params.projectId, this.meetingId),
                    getBundles(this.$route.params.projectId),
                    getProject(this.$route.params.projectId),
                ]).subscribe(([meeting, bundles, project]) => {
                    this.isMOA = project.me.allowedFeatures.includes('project_meetingReportsMOA');
                    this.isMOE = project.me.allowedFeatures.includes('project_meetingReportsMOE');
                    this.disableType = !(this.isMOA && this.isMOE);
                    this.isReadOnly =
                        (meeting.type === 'MOA' && !this.isMOA) || (meeting.type === 'MOE' && !this.isMOE);
                    this.bundles = bundles;
                    const bundleMap = getMapById(bundles);
                    if (meeting) {
                        this.meeting = {
                            ...meeting,
                            convocations: sortBy(
                                meeting.convocations.map((convocation) => ({
                                    ...convocation,
                                    convocationDate: convocation.convocationDate
                                        ? new Date(convocation.convocationDate)
                                        : null,
                                    bundle: bundleMap[convocation.bundleId],
                                })),
                                'convocationDate',
                            ),
                        };
                        this.lastHour = this.meeting.date;
                        this.selectedConvocation = this.meeting.convocations.map((convocation) => convocation.bundle);
                    }
                }),
            ];
        },
        meetingDateChanged() {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                date: this.meeting.date,
                convocations: this.meeting.convocations.map((convocation) => {
                    if (!convocation.convocationDate) {
                        convocation.convocationDate = this.meeting.date;
                    }
                    const hours = convocation.convocationDate.getHours();
                    const minutes = convocation.convocationDate.getMinutes();
                    convocation.convocationDate = new Date(this.meeting.date);
                    convocation.convocationDate.setHours(hours);
                    convocation.convocationDate.setMinutes(minutes);
                    return convocation;
                }),
            });
        },
        meetingHourChanged() {
            this.meeting.convocations.map((convocation) => {
                convocation.convocationDate = this.meeting.date;
            });
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                date: this.meeting.date,
                convocations: this.meeting.convocations.map((convocation) => {
                    convocation.convocationDate = this.meeting.date;
                    return convocation;
                }),
            });
        },
        updateConvocations() {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                convocations: this.meeting.convocations.map((convocation) => ({
                    attendance: convocation.attendance,
                    convocationDate: convocation.convocationDate ? convocation.convocationDate.toISOString() : null,
                    bundleId: convocation.bundleId,
                })),
            });
        },
        save() {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                name: this.meeting.name,
                location: this.meeting.location,
            });
        },
        onUncheck(bundles) {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                convocations: this.meeting.convocations
                    .filter((convocation) => !bundles.find((bundle) => bundle.id === convocation.bundleId))
                    .map((convocation) => ({
                        attendance: convocation.attendance,
                        convocationDate: convocation.convocationDate ? convocation.convocationDate.toISOString() : null,
                        bundleId: convocation.bundleId,
                    })),
            });
        },
        onCheck(bundles) {
            return updateMeeting(this.$route.params.projectId, {
                id: this.meeting.id,
                convocations: [
                    ...this.meeting.convocations
                        .filter((convocation) => !bundles.find((bundle) => bundle.id === convocation.bundleId))
                        .map((convocation) => ({
                            attendance: convocation.attendance,
                            convocationDate: convocation.convocationDate
                                ? convocation.convocationDate.toISOString()
                                : null,
                            bundleId: convocation.bundleId,
                        })),
                    ...bundles.map((bundle) => ({
                        convocationDate: this.meeting.date,
                        bundleId: bundle.id,
                        attendance: 'present',
                    })),
                ],
            });
        },
    },
    data() {
        return {
            subscriptions: [],
            lastHour: null,
            bundles: [],
            meeting: { convocations: [] },
            selectedConvocation: [],
            disableType: false,
            isMOA: false,
            isMOE: false,
            isReadOnly: true,
        };
    },
};
</script>
