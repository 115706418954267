<template>
    <main class="max-w-5xl m-5 mb-10 w-full">
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2">
                <app-text-editor
                    :label="$t('project.follow.requests.description')"
                    v-model="request.description"
                    end-point="documents?scope=requestContent"
                    @blur="save"
                    class="col-span-2"
                    :showToolbar="true"
                ></app-text-editor>
                <app-fieldset class="col-span-2 mt-4" :label="$t('project.follow.observation.attachments')">
                    <div class="col-span-2 flex">
                        <app-multiple-upload-button
                            ref="upload"
                            icon="icon-paperclip"
                            size="mini"
                            :label="$t('observations.newAttachment')"
                            :end-point="`/api/projects/${$route.params.projectId}/requests/${request.id}/attachment`"
                            :resize="1024"
                            :name="$t('project.follow.weatherIssue.newAttachment')"
                            class="text-sm offline:hidden"
                            @loaded="onNewAttachment"
                        />
                        <app-offline-upload-button
                            ref="fileInput"
                            icon="icon-paperclip"
                            size="mini"
                            :label="$t('observations.newAttachment')"
                            class="text-sm hidden offline:block"
                            @select="onNewOfflineAttachment"
                        />
                    </div>
                    <div class="col-span-2 flex gap-2 flex-wrap">
                        <template v-for="attachment in request.attachments">
                            <app-file-link
                                :fileName="attachment.name"
                                :url="attachment.url"
                                v-if="attachment.url && attachment.url.endsWith('pdf')"
                                :to="{
                                    name: 'fileViewer',
                                    params: {
                                        projectId: $route.params.projectId,
                                    },
                                    query: {
                                        url: attachment.url,
                                        backName: $t('observations.title'),
                                    },
                                }"
                                @delete="onDeleteAttachment(attachment)"
                            ></app-file-link>
                            <app-photo
                                v-else
                                @click.native="openPhotoPopup(attachment)"
                                :url="attachment.url || attachment.dataUrl"
                                class="m-2 cursor-pointer"
                                @delete="onDeleteAttachment(attachment)"
                            ></app-photo>
                        </template>
                    </div>
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-4" :label="$t('project.follow.observation.status')">
                    <RequestStatus :request="request" class="col-span-2" @input="save" @remove="removeRequest" />
                </app-fieldset>
                <app-fieldset
                    class="col-span-2 mt-4"
                    :label="$t('project.follow.requests.observations')"
                    v-if="isMOE || isMOA"
                >
                    <div>
                        <app-button size="mini" :label="$t('observations.newObservation')" @click="onNewObservation" />
                    </div>
                    <div class="col-span-2 flex gap-2 flex-col">
                        <table class="table-fixed text-xs" v-if="request.observations.length > 0">
                            <thead>
                                <tr>
                                    <th style="width: 2rem" class="text-left border-r p-1">#</th>
                                    <th class="text-left border-r p-1 bg-white">
                                        <span>{{ $t('project.follow.requests.description') }}</span>
                                    </th>
                                    <th style="width: 15rem" class="text-left border-r p-1 bg-white">
                                        <span>{{ $t('project.follow.observation.status') }}</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="observation in request.observations">
                                    <tr class="odd:bg-blue-50 border-t">
                                        <td class="border-r p-1">
                                            {{ observation.index | index }}
                                        </td>
                                        <td class="border-r p-1">
                                            <router-link
                                                :to="{
                                                    name: 'followObservation',
                                                    params: {
                                                        projectId: $route.params.projectId,
                                                        observationId: observation.id,
                                                    },
                                                }"
                                            >
                                                <app-clamp :htmlContent="observation.title" :number-of-lines="2" />
                                            </router-link>
                                        </td>
                                        <td class="border-r p-1">
                                            <span>{{ observation.statusLabel }}</span>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </app-fieldset>
                <app-fieldset class="col-span-2 mt-4" :label="$t('project.follow.observation.location')">
                    <app-picker
                        v-model="request.zone"
                        :options="zones"
                        class="col-span-2"
                        :show-label="true"
                        label-key="fullName"
                        :label="$t('project.follow.observation.zone')"
                        @input="onZoneChanged"
                    />
                    <app-picker
                        v-model="request.room"
                        class="col-span-2"
                        :options="roomOptions"
                        :show-label="true"
                        :label="$t('observations.room')"
                        @input="save"
                    />
                    <app-select
                        :label="$t('observations.map')"
                        :value="request.supportId"
                        class="col-span-2"
                        @change="onSupportChanged"
                    >
                        <option :value="support.id" v-for="support in supportOptions">
                            {{ support.name }}
                        </option>
                    </app-select>
                    <div class="col-span-2 flex justify-end" v-if="request.footprint">
                        <app-button
                            :label="$t('observations.removeMarkerFromMap')"
                            @click="removeFootprint()"
                            variant="danger"
                            size="mini"
                        />
                    </div>
                    <app-leaflet-viewer
                        class="col-span-2 mx-5 min-h-main"
                        v-if="request.zone && request.support"
                        :src="request.support.url"
                        :crop-box="request.support.cropBox"
                        :page="1"
                        :markers="markers"
                        :rotation="request.support.rotation"
                        @markerMoved="onMarkerMoved"
                        @pickPosition="onMarkerMoved"
                        :allow-marker-move="true"
                    ></app-leaflet-viewer>
                </app-fieldset>
                <app-popup ref="popup" class="md:max-w-3/4 min-h-main max-h-main" :show-header="true">
                    <app-photo-editor :attachment="selectedAttachment" :read-only="false"></app-photo-editor>
                </app-popup>
            </form>
            <app-save-on-leave :dirty="dirty" :saveFn="saveOnLeave"></app-save-on-leave>
        </ValidationObserver>
    </main>
</template>

<script>
import { omit } from '@/services/sanitize.service';
import { combineLatest } from 'rxjs';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import AppFieldset from '@/components/appFieldset/AppFieldset.vue';
import AppTextEditor from '@/components/appTextEditor/AppTextEditor.vue';
import AppTips from '@/components/app-tips/AppTips.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import MailTemplateTips from '@/features/mailTemplates/MailTemplateTips.vue';
import { updateBreadCrumbs } from '@/state/state';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions.vue';
import { queryProject } from '@/features/projects/projects.service';
import AppPicker from '@/components/appPicker/AppPicker.vue';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton.vue';
import AppOfflineUploadButton from '@/components/app-offlineUploadButton/AppOfflineUploadButton.vue';
import AppLeafletViewer from '@/components/appLeafletViewer/AppLeafletViewer.vue';
import AppWarnOnLeave from '@/components/AppWarnOnLeave.vue';
import AppPopup from '@/components/app-popup/AppPopup.vue';
import AppPhotoEditor from '@/components/appPhotoEditor/AppPhotoEditor.vue';
import AppSaveOnLeave from '@/components/AppSaveOnLeave.vue';
import { getRequest, removeRequest, updateRequest } from '@/features/requests/requests.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getSupports } from '@/features/supports/supports.service';
import locationService from '@/services/location.service';
import {
    createRequestAttachment,
    getRequestAttachmentsByRequestId,
    removeRequestAttachment,
} from '@/features/requests/requestAttachments.service';
import { confirm } from '@/features/dialogs/dialogs.service';
import {
    createObservation,
    getObservationClass,
    getObservationsForRelatedContent,
} from '@/features/observations/observation.service';
import AppPhoto from '@/components/appPhoto/AppPhoto.vue';
import { index } from '@/filters/indexFilter';
import AppClamp from '@/components/appClamp/AppClamp.vue';
import { mapObservationStatus } from '@/features/meetings/byBundle.service';
import { createObservationAttachment } from '@/features/observations/observationAttachments.service';
import RequestStatus from '@/features/requests/RequestStatus.vue';
import AppFileLink from '@/components/appFileLink/AppFileLink.vue';

export default {
    components: {
        AppFileLink,
        RequestStatus,
        AppClamp,
        AppPhoto,
        AppSaveOnLeave,
        AppPhotoEditor,
        AppPopup,
        AppWarnOnLeave,
        AppLeafletViewer,
        AppOfflineUploadButton,
        AppMultipleUploadButton,
        AppPicker,
        AppQuickActions,
        MailTemplateTips,
        AppButton,
        AppTips,
        AppTextEditor,
        AppFieldset,
        AppMultiPicker,
        AppSelect,
        AppInputText,
    },
    created() {
        updateBreadCrumbs({ requestName: '' });
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    computed: {
        markers() {
            return this.request.footprint
                ? [
                      {
                          ...this.request.footprint,
                          label: this.request.index + 1,
                          classes: this.request.classes,
                          draggable: true,
                      },
                  ]
                : [];
        },
        zoneOptions() {
            return this.zones;
        },
        roomOptions() {
            if (this.request.zoneId) {
                return this.rooms.filter((room) => room.parentId === this.request.zoneId);
            } else {
                return [];
            }
        },
        supportOptions() {
            if (this.request.zoneId) {
                return this.supports.filter((support) => support.locationIds.includes(this.request.zoneId));
            } else {
                return [];
            }
        },
    },
    methods: {
        init() {
            queryProject(this.$route.params.projectId).then((project) => {
                this.project = project;
                this.isMOA = project.me.allowedFeatures.includes('project_requestsMOAValidation');
                this.isMOE = project.me.allowedFeatures.includes('project_requestsMOEValidation');
            });
            this.subscriptions = [
                combineLatest([
                    getRequest(this.$route.params.projectId, this.$route.params.requestId),
                    getRequestAttachmentsByRequestId(this.$route.params.projectId, this.$route.params.requestId),
                    getLocationsTree(this.$route.params.projectId),
                    getSupports(this.$route.params.projectId),
                    getObservationsForRelatedContent(this.$route.params.projectId, this.$route.params.requestId),
                ]).subscribe(([request, requestAttachments, folders, supports, observations]) => {
                    const locationMap = locationService.getLocationMap(folders);
                    this.request = {
                        ...request,
                        attachments: requestAttachments,
                        zone: locationMap[request.zoneId],
                        room: locationMap[request.roomId],
                        support: request.supportId
                            ? supports.find((support) => support.id === request.supportId)
                            : null,
                        classes: getObservationClass(request),
                        observations: observations.map((observation) => ({
                            ...observation,
                            statusLabel: mapObservationStatus(observation, null, (a) => this.$t(a)).label,
                        })),
                    };
                    this.zones = folders
                        .reduce((acc, folder) => [...acc, ...folder.locations], [])
                        .reduce((acc, location) => [...acc, ...location.zones], [])
                        .map((zone) => ({
                            ...zone,
                            supports: supports.filter(
                                (support) => support.locationIds.includes(zone.id) && support.emissionDate,
                            ),
                        }));
                    this.rooms = folders
                        .reduce((acc, folder) => [...acc, ...folder.locations], [])
                        .reduce((acc, location) => [...acc, ...location.zones], [])
                        .reduce((acc, zone) => [...acc, ...zone.rooms], []);
                    this.supports = supports.filter((support) => support.sharedWithDeliveryContacts);
                    updateBreadCrumbs({
                        requestName: this.$t('project.follow.requests.request') + ' ' + index(request.index),
                    });

                    if (this.selectedAttachment) {
                        this.selectedAttachment = requestAttachments.find(
                            (attachment) => attachment.url === this.selectedAttachment.url,
                        );
                    }
                }),
            ];
        },

        async removeRequest() {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                this.hasBeenDeleted = true;
                await removeRequest(this.$route.params.projectId, this.request.id);
                await this.$router.push({
                    name: 'requests',
                    params: {
                        projectId: this.$route.params.projectId,
                    },
                });
            }
        },
        async onNewObservation() {
            const observation = await createObservation(this.$route.params.projectId, {
                title: '',
                phase: this.project.phase,
                relatedContentId: this.request.id,
                relatedContentType: 'request',
                footprint: JSON.parse(JSON.stringify(this.request.footprint)),
                supportId: this.request.supportId,
                zoneId: this.request.zoneId,
                roomId: this.request.roomId,
                type: 'onDoneWork',
            });
            await Promise.all(
                this.request.attachments.map((attachment) =>
                    createObservationAttachment(this.$route.params.projectId, {
                        url: attachment.url,
                        shapes: JSON.parse(JSON.stringify(attachment.shapes)),
                        name: attachment.name,
                        observationId: observation.id,
                    }),
                ),
            );
            await this.$router.push({
                name: 'followObservation',
                params: {
                    projectId: this.$route.params.projectId,
                    observationId: observation.id,
                },
            });
        },
        onZoneChanged(newZone) {
            if (!newZone.supports.includes(this.request.supportId)) {
                this.request.supportId = null;
                this.request.support = null;
            }
            this.request.roomId = null;
            this.request.room = null;
            this.save();
        },
        onSupportChanged(supportId) {
            this.request.supportId = supportId;
            this.request.footprint = null;
            this.save();
        },
        onMarkerMoved(event) {
            this.request.footprint = { x: event.x, degree: event.degree, y: event.y, type: 'marker', page: 1 };
            this.save();
        },
        openPhotoPopup(attachment) {
            this.selectedAttachment = attachment;
            this.$refs.popup.open();
        },
        onNewAttachment(attachment) {
            createRequestAttachment(this.$route.params.projectId, attachment);
        },
        onNewOfflineAttachment({ dataUrl }) {
            createRequestAttachment(this.$route.params.projectId, { dataUrl, requestId: this.request.id });
        },
        async onDeleteAttachment(attachment) {
            if (await confirm(this.$t('commons.confirmMessage'))) {
                return removeRequestAttachment(this.$route.params.projectId, attachment.id);
            }
        },
        removeFootprint() {
            this.request.footprint = null;
            this.save();
        },
        saveOnLeave() {
            if (!this.hasBeenDeleted) {
                this.save();
            }
        },
        save() {
            return updateRequest(this.$route.params.projectId, {
                ...omit(this.request, ['zone', 'room', 'support', 'attachments', 'classes', 'observations']),
                zoneId: this.request.zone?.id || null,
                roomId: this.request.room?.id || null,
                footprint: this.request.footprint ? JSON.parse(JSON.stringify(this.request.footprint)) : null,
            });
        },
    },
    data() {
        return {
            request: {
                attachments: [],
                observations: [],
            },
            subscriptions: [],
            quickActions: [],
            zones: [],
            supports: [],
            selectedSupport: null,
            rooms: [],
            selectedAttachment: null,
            project: {},
            isMOA: false,
            isMOE: false,
        };
    },
};
</script>
