<template>
    <app-label
        :show-label="showLabel === true || (showLabel === 'onFocus' && hasFocus)"
        :label="label"
        :class="{ 'cursor-pointer': !disabled, 'flex-col': showLabel === 'onFocus' }"
        class="flex gap-1 relative"
    >
        <slot v-if="!hasFocus">
            <div :class="{ 'hover:underline': !disabled }" class="flex">
                <span v-if="internalValue || internalValue === 0">{{ internalValue }}</span>
                <span v-else>...</span>
            </div>
        </slot>
        <input
            :style="{ opacity }"
            :class="{ absolute: !opacity }"
            :disabled.prop="disabled"
            class="bg-inherit w-full"
            style="left: -1000px"
            type="text"
            v-model="internalValue"
            @focus="onFocus"
            @blur="onBlur"
            @keyup.enter="validate"
            @keyup.esc="discard"
            @keydown.enter.prevent
            ref="input"
        />
    </app-label>
</template>
<script>
import AppLabel from '@/components/appLabel/AppLabel';
export default {
    components: { AppLabel },
    watch: {
        edited(edited) {
            if (edited) {
                this.onFocus();
            }
        },
        value(value) {
            this.internalValue = value;
        },
    },
    created() {
        this.internalValue = this.value;
        if (this.edited) {
            this.onFocus();
        }
    },
    data() {
        return {
            opacity: 0,
            hasFocus: false,
            preventBlur: false,
            internalValue: null,
        };
    },
    methods: {
        focus() {
            this.$refs.input.focus();
        },
        discard() {
            this.preventBlur = true;
            this.reset();
        },
        validate(event) {
            event.preventDefault();
            this.preventBlur = true;
            this.hasFocus = false;
            this.reset();
            this.save(event.target.value);
            this.$emit('enter');
        },
        reset() {
            this.opacity = 0;
            this.internalValue = this.value;
            this.$refs.input.blur();
        },
        save(value) {
            this.$emit('input', value);
        },
        onFocus() {
            if (!this.hasFocus) {
                this.hasFocus = true;
                this.opacity = 1;
                this.preventBlur = false;
                setTimeout(() => {
                    {
                        this.$refs.input.select();
                        this.$emit('focus');
                    }
                }, 100);
            }
        },
        onBlur($event) {
            if (!this.preventBlur) {
                this.hasFocus = false;
                this.reset();
                if ($event.target.value !== this.value) {
                    this.save($event.target.value);
                }
                this.$emit('blur');
            }
        },
    },
    props: {
        label: String,
        disabled: { type: Boolean, default: false },
        value: String,
        showLabel: { type: String | Boolean, default: true },
        edited: { type: Boolean, default: false },
    },
};
</script>
