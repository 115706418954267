<template>
    <app-popup
        ref="popup"
        :title="contact.id ? $t('users.updateUser') : $t('companies.createNewUser')"
        :show-header="true"
    >
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer" class="p-2">
            <form class="flex flex-col gap-2">
                <app-input-text
                    :label="$t('users.lastName')"
                    v-model="contact.lastName"
                    :required="true"
                    class="col-span-2"
                />
                <app-input-text :label="$t('users.firstName')" v-model="contact.firstName" class="col-span-2" />
                <app-input-text :label="$t('users.role')" v-model="contact.role" class="col-span-2" />
                <app-phone-input v-model="contact.phone" class="col-span-2" />
                <app-input-text
                    :label="$t('users.email')"
                    v-model="contact.email"
                    type="email"
                    class="col-span-2"
                    @paste="onPasteMail"
                />
                <app-checkbox
                    :label="$t('users.isReportRecipient')"
                    v-model="contact.isReportRecipient"
                    class="col-span-2"
                    v-if="showIsRecipient"
                />
                <app-footer @click="save" :disabled="invalid" class="mt-2"></app-footer>
            </form>
        </ValidationObserver>
    </app-popup>
</template>

<script>
import AppPopup from '@/components/app-popup/AppPopup';
import AppInputText from '@/components/appInputText/AppInputText';
import AppPhoneInput from '@/components/appPhoneInput/AppPhoneInput';
import AppFooter from '@/components/appFooter/AppFooter';
import AppFieldset from '@/components/appFieldset/AppFieldset';
import AppButton from '@/components/appButton/AppButton';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
export default {
    components: { AppCheckbox, AppButton, AppFieldset, AppFooter, AppPhoneInput, AppInputText, AppPopup },
    props: {
        contact: Object,
        showIsRecipient: Boolean,
    },
    methods: {
        open() {
            this.$refs.popup.open();
        },
        onPasteMail() {
            setTimeout(() => {
                this.contact.email = this.contact.email
                    ? this.contact.email.replace('mailto:', '').replaceAll(' ', '')
                    : '';
            }, 100);
        },
        async save() {
            this.$refs.observer.reset();
            this.$refs.popup.close();
            this.$emit('save', this.contact);
        },
    },
};
</script>
