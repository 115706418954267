<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full">
            <div class="flex w-full sm:flex-row gap-2">
                <app-button @click="addSupport()" :label="$t('supports.newSupport')" v-if="!readOnly" />
                <app-multiple-upload-button
                    :label="$t('supports.multipleImports')"
                    :end-point="`/api/projects/${$route.params.projectId}/supports`"
                    @error="warnError"
                    class="offline:hidden text-sm"
                    v-if="!readOnly"
                />
            </div>
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            >
                <template v-slot:option="{ option }">
                    <span>{{ option.name }}</span>
                    <span class="text-xs text-gray-600 ml-1">{{ option.criteriaType }}</span>
                </template>
            </app-multi-picker>

            <div class="flex justify-between">
                <div class="flex justify-center items-center">
                    <app-select @input="onAction" v-if="!loading && selection.length > 0 && !readOnly" class="text-xs">
                        <option value="" disabled selected>{{ $t('commons.actions') }}</option>
                        <option value="modify">{{ $t('commons.actionModify') }}</option>
                        <option value="delete">{{ $t('commons.actionDelete') }}</option>
                        <option value="downloadAll">{{ $t('commons.actionDownloadAll') }}</option>
                    </app-select>
                    <icon-rotate-right v-if="prepareZip" class="animate animate-spin"></icon-rotate-right>
                </div>
                <div class="w-full flex flex-col justify-start gap-1 sm:gap-4 sm:justify-center sm:flex-row text-sm">
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="done"
                        :label="$t('commons.emitted') + ' (' + doneCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="coming"
                        :label="$t('project.follow.follow.coming') + ' (' + comingCount + ')'"
                    ></app-checkbox>
                    <app-checkbox
                        class="justify-end sm:justify-center"
                        v-model="far"
                        :label="$t('project.follow.follow.far') + ' (' + farCount + ')'"
                    ></app-checkbox>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 2rem" class="text-left border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                            <app-checkbox
                                :value="selection.length === filteredItems.length && selection.length > 0"
                                :indeterminate="selection.length > 0 && selection.length < filteredItems.length"
                                :show-label="false"
                                :label="$t('commons.toggleSelectAll')"
                                @input="toggleSelectAll"
                            ></app-checkbox>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('code')"
                            >
                                {{ $t('supports.code') }}
                                <div v-if="sortKey === 'code'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('type')"
                            >
                                {{ $t('supports.type') }}
                                <div v-if="sortKey === 'type'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th style="width: 2rem" class="text-left border-r p-1">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('version')"
                            >
                                {{ $t('commons.version') }}
                                <div v-if="sortKey === 'version'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('name')"
                            >
                                <span>{{ $t('commons.name') }}</span>
                                <div v-if="sortKey === 'name'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>

                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: auto">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emitter')"
                            >
                                {{ $t('bundles.emitter') }}
                                <div v-if="sortKey === 'emitter'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: 6rem">
                            <button
                                class="hover:underline font-bold w-full flex justify-between"
                                @click="sortBy('emissionDate')"
                            >
                                {{ $t('commons.emittedOn') }}
                                <div v-if="sortKey === 'emissionDate'">
                                    <icon-menu-up v-if="sortAsc" width="16" height="16" />
                                    <icon-menu-down v-else width="16" height="16" />
                                </div>
                            </button>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="support in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1 hidden sm:table-cell" v-if="!readOnly">
                                <a :id="'uuid_' + support.id" style="scroll-margin-top: 3em"></a>
                                <app-checkbox
                                    :value="support.isSelected"
                                    :label="$t('commons.select')"
                                    :show-label="false"
                                    @input="saveSelection(support)"
                                ></app-checkbox>
                            </td>
                            <td class="border-r p-1">
                                {{ support.code }}
                            </td>
                            <td class="border-r p-1">
                                <span :title="$t('supports.types.text')" v-if="support.type === 'text'">
                                    <icon-file-document-outline width="16" height="16" />
                                </span>
                                <span :title="$t('supports.types.graphic')" v-else-if="support.type === 'graphic'">
                                    <icon-file-image-outline width="16" height="16" />
                                </span>
                                <span :title="$t('supports.types.other')" v-else>
                                    <icon-file-outline width="16" height="16" />
                                </span>
                            </td>
                            <td class="text-center border-r p-1">
                                {{ support.versions.length }}
                            </td>
                            <td class="border-r p-1">
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="hover:underline"
                                        :to="{
                                            name: 'support',
                                            params: {
                                                ...$route.params,
                                                supportId: support.id,
                                            },
                                        }"
                                    >
                                        {{ support.name }}
                                        <span v-if="!support.name || support.name.trim().length === 0">...</span>
                                    </router-link>
                                    <div class="flex" v-if="support.emissionDate && support.url">
                                        <app-file-link
                                            :url="support.url"
                                            :fileName="support.name"
                                            :showName="false"
                                            class="offline:hidden"
                                        ></app-file-link>
                                    </div>
                                </div>
                            </td>
                            <td class="text-left border-r p-1 hidden sm:table-cell">
                                <div v-if="support.emitter">
                                    {{ support.emitter.label }}
                                </div>
                            </td>
                            <td class="text-center border-r p-1 hidden sm:table-cell">
                                <app-date-link
                                    @enter="focusToNextDate(support)"
                                    :ref="'date_' + support.id"
                                    :disabled="readOnly"
                                    :label="$t('supports.emissionDate')"
                                    :show-label="false"
                                    v-model="support.emissionDate"
                                    @input="updateEmissionDate(support, $event)"
                                ></app-date-link>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
        <app-popup ref="modifyAllPopup" :showHeader="true" :title="$t('commons.actionOnSelection')">
            <ValidationObserver
                v-slot="{ invalid, errors, dirty }"
                tag="form"
                ref="observer"
                class="p-2 gap-2 flex flex-col"
            >
                <app-select :label="$t('supports.type')" v-model="editedSupport.type" class="col-span-2">
                    <option></option>
                    <option value="text">{{ $t('supports.types.text') }}</option>
                    <option value="graphic">{{ $t('supports.types.graphic') }}</option>
                    <option value="other">{{ $t('supports.types.other') }}</option>
                </app-select>

                <app-bundle-picker v-model="editedSupport.emitter" :options="bundles"></app-bundle-picker>

                <app-date-input
                    v-model="editedSupport.emissionDueDate"
                    :label="$t('supports.emissionDueDate')"
                ></app-date-input>

                <app-date-input v-model="editedSupport.emissionDate" :label="$t('supports.emissionDate')" />
                <app-input-text v-model="editedSupport.code" :label="$t('commons.code')" />
                <app-select
                    v-if="editedSupport.type === 'graphic'"
                    v-model="editedSupport.sharedWithDeliveryContacts"
                    :label="$t('supports.sharedWithDeliveryContacts')"
                >
                    <option value=""></option>
                    <option value="shared">Partagé</option>
                    <option value="notShared">Non Partagé</option>
                </app-select>

                <app-footer @click="saveMultiple()" :disabled="invalid" class="mt-2"></app-footer>
            </ValidationObserver>
        </app-popup>
    </main>
</template>

<script>
import { sortBy, filterMatch } from '@/services/sanitize.service';
import { differenceInWorkingDays } from '@/services/duration.service';
import locationService from '../../services/location.service';
import AppSelect from '@/components/appSelect/AppSelect';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions';
import { combineLatest } from 'rxjs';
import { updateSupport, getSupports, removeSupportGroup, createSupport } from './supports.service';

import AppDateLink from '@/components/appDateLink/AppDateLink';
import { confirm } from '@/features/dialogs/dialogs.service';
import AppPopup from '@/components/app-popup/AppPopup';
import AppBundlePicker from '@/components/appBundlePicker';
import AppDateInput from '@/components/appDateInput/AppDateInput';
import AppFooter from '@/components/appFooter/AppFooter';
import { error } from '@/features/toasts/toats.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker';
import AppButton from '@/components/appButton/AppButton';
import IconFileDocumentOutline from '@/icons/IconFileDocumentOutline';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import AppMultipleUploadButton from '@/components/app-multipleUploadButton/AppMultipleUploadButton';
import IconFileImageOutline from '@/icons/IconFileImageOutline';
import { queryProject } from '@/features/projects/projects.service';
import { getLocationsTree } from '@/features/locations/locations.service';
import { getCalendar } from '@/features/planning/agenda/agenda.service';
import { getBundleMap, getBundles } from '@/features/bundles/bundles.service';

import { humanizeDate } from '@/filters/dateFilter';
import { getAPIHeaders } from '@/services/api.service';
import AppInputText from '@/components/appInputText/AppInputText.vue';
export default {
    components: {
        AppInputText,
        IconFileImageOutline,
        AppMultipleUploadButton,
        AppFileLink,
        IconFileDocumentOutline,
        AppButton,
        AppMultiPicker,
        AppFooter,
        AppDateInput,
        AppBundlePicker,
        AppPopup,
        AppQuickActions,
        AppDateLink,
        AppCheckbox,
        AppSelect,
    },
    async created() {
        this.loading = true;
        this.restoreFilter();
        queryProject(this.$route.params.projectId).then((project) => {
            this.readOnly = !project.me.allowedFeatures.includes('project_supports');
            this.me = project.me;
        });
        this.subscriptions = [
            getCalendar(this.$route.params.projectId).subscribe((calendar) => {
                this.calendar = calendar;
            }),
            combineLatest([
                getSupports(this.$route.params.projectId),
                getLocationsTree(this.$route.params.projectId),
                getBundleMap(this.$route.params.projectId),
            ]).subscribe(([supports, folders, bundleMap]) => {
                this.restoreSelection();
                this.locationOptions = locationService
                    .buildLocationOptions(folders)
                    .filter((location) => location.type === 'zone');
                const locationMap = locationService.getLocationMap(folders);
                this.items = supports.map((support) => {
                    return {
                        ...support,
                        filterString: [
                            support.code,
                            support.name,
                            bundleMap[support.emitterId]?.label,
                            support.emissionDate ? humanizeDate(support.emissionDate) : '',
                        ].join(),
                        isSelected: this.selection.includes(support.id),
                        locations: support.locationIds.map((id) => locationMap[id]),
                        emitter: bundleMap[support.emitterId],
                    };
                });
                this.cleanupSavedSelection();

                const lastVisitedSupportId = localStorage.getItem(
                    'support.lastVisitedSupportId.' + this.$route.params.projectId,
                );
                if (lastVisitedSupportId) {
                    this.$nextTick(() => {
                        const element = this.$el.querySelector('#uuid_' + lastVisitedSupportId);
                        if (element) element.scrollIntoView();
                    });
                }
                this.loading = false;
            }),
            getBundles(this.$route.params.projectId).subscribe((bundles) => {
                this.bundles = bundles;
            }),
        ];
    },
    computed: {
        doneCount() {
            return this.items.filter((item) => this.isDone(item)).length;
        },
        farCount() {
            return this.items.filter((item) => this.isFar(item)).length;
        },
        comingCount() {
            return this.items.filter((item) => !this.isDone(item) && !this.isFar(item)).length;
        },
        quickActions() {
            return [
                {
                    name: this.$t('supports.newSupport'),
                    run: () => this.addSupport(),
                },
            ];
        },
        filteredItems() {
            let result = this.filterFn(this.filterValue).map((item) => ({
                ...item,
                isSelected: this.selection.includes(item.id),
            }));
            if (this.sortKey) {
                result = sortBy(result, (item) => {
                    if (this.sortKey === 'code') {
                        return item.code;
                    } else if (this.sortKey === 'name') {
                        return item.name;
                    } else if (this.sortKey === 'emissionDueDate') {
                        return item.emissionDueDate;
                    } else if (this.sortKey === 'emissionDate') {
                        return item.emissionDate;
                    } else if (this.sortKey === 'type') {
                        return item.type;
                    } else if (this.sortKey === 'emitter') {
                        return item.emitter?.label;
                    }
                });
                if (!this.sortAsc) {
                    result.reverse();
                }
            }
            return result;
        },
        filterOptions() {
            return [
                {
                    isGroup: true,
                    name: this.$t('commons.typeCriteriaType'),
                    id: this.$t('commons.typeCriteriaType'),
                    children: this.typeFilterOptions,
                },
                {
                    isGroup: true,
                    name: this.$t('commons.bundleCriteriaType'),
                    id: this.$t('commons.bundleCriteriaType'),
                    children: [
                        {
                            id: 'noEmitterCriteria',
                            name: '- ' + this.$t('supports.noEmitterCriteria') + ' -',
                            firstOfCriteriaType: true,
                            criteriaType: this.$t('supports.noEmitterCriteria'),
                            _isNoEmitterCriteria: true,
                        },
                        ...this.bundles.map((bundle) => ({
                            ...bundle,
                            name: bundle.label,
                            firstOfCriteriaType: false,
                            criteriaType: this.$t('commons.bundleCriteriaType'),
                            _isBundleCriteria: true,
                        })),
                    ],
                },
                {
                    isGroup: true,
                    name: this.$t('commons.locationCriteriaType'),
                    id: this.$t('commons.locationCriteriaType'),
                    children: this.locationOptions.map((location, index) => ({
                        ...location,
                        name: location.fullName,
                        firstOfCriteriaType: index === 0,
                        criteriaType: this.$t('commons.locationCriteriaType'),
                        _isLocationCriteria: true,
                    })),
                },
            ];
        },
    },
    methods: {
        async warnError() {
            error(this.$t('errors.INTERNAL_SERVER_ERROR'));
        },
        sortBy(key) {
            if (key === this.sortKey) {
                this.sortAsc = !this.sortAsc;
            } else {
                this.sortKey = key;
                this.sortAsc = true;
            }
            this.saveFilter(this.filterValue);
        },
        saveMultiple() {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            const patch = {};
            if (
                this.editedSupport.emissionDueDate &&
                this.editedSupport.emissionDueDate !== this.getSelectionCommonEmissionDueDate(selectedItems)
            ) {
                patch.emissionDueDate = this.editedSupport.emissionDueDate;
            }
            if (
                this.editedSupport.emitter &&
                this.editedSupport.emitter !== this.getSelectionCommonEmitter(selectedItems)
            ) {
                patch.emitterId = this.editedSupport.emitter.id;
            }
            if (
                this.editedSupport.emissionDate &&
                this.editedSupport.emissionDate !== this.getSelectionCommonEmissionDate(selectedItems)
            ) {
                patch.emissionDate = this.editedSupport.emissionDate;
            }

            if (this.editedSupport.type && this.editedSupport.type !== this.getSelectionCommonType(selectedItems)) {
                patch.type = this.editedSupport.type;
            }

            if (this.editedSupport.code && this.editedSupport.code !== this.getSelectionCommonCode(selectedItems)) {
                patch.code = this.editedSupport.code;
            }

            if (
                this.editedSupport.sharedWithDeliveryContacts === 'notShared' ||
                this.editedSupport.sharedWithDeliveryContacts === 'shared'
            ) {
                patch.sharedWithDeliveryContacts = this.editedSupport.sharedWithDeliveryContacts === 'shared';
            }

            this.selection.map(async (itemId) => {
                if (Object.keys(patch).length > 0) {
                    await updateSupport(this.$route.params.projectId, { id: itemId, ...patch });
                }
            });
            this.$refs.modifyAllPopup.close();
        },
        async onAction(action) {
            const selectedItems = this.items.filter((item) => this.selection.includes(item.id));
            if (action === 'delete') {
                if (await confirm(this.$t('commons.confirmMessageAll', { number: this.selection.length }))) {
                    await Promise.all(
                        selectedItems.map((item) => removeSupportGroup(this.$route.params.projectId, item.groupId)),
                    );
                    this.selection = [];
                }
            } else if (action === 'modify') {
                this.editedSupport = {
                    emissionDueDate: this.getSelectionCommonEmissionDueDate(selectedItems),
                    emissionDate: this.getSelectionCommonEmissionDate(selectedItems),
                    emitter: this.getSelectionCommonEmitter(selectedItems),
                    type: this.getSelectionCommonType(selectedItems),
                    sharedWithDeliveryContacts: this.getSelectionCommonSharedWithDeliveryContacts(selectedItems),
                    code: this.getSelectionCommonCode(selectedItems),
                };
                this.$refs.modifyAllPopup.open();
            } else if (action === 'downloadAll') {
                this.prepareZip = true;
                const response = await fetch(
                    new Request(`/api/projects/${this.$route.params.projectId}/supports/attachments`, {
                        method: 'POST',
                        headers: getAPIHeaders({ 'content-type': `application/json` }),
                        body: JSON.stringify(selectedItems.map((item) => item.id)),
                    }),
                );
                this.downloadAsFile(response);
                this.prepareZip = false;
            }
        },
        async downloadAsFile(response) {
            const a = document.createElement('a');
            const fileContents = await response.blob();
            const url = URL.createObjectURL(fileContents);
            a.setAttribute('href', url);
            a.download = 'supports.zip';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(function () {
                URL.revokeObjectURL(a.href);
            }, 1500);
        },
        getSelectionCommonEmitter(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emitter);
            if (firstItem && selectedItems.every((item) => item.emitter && item.emitter.id === firstItem.emitter.id)) {
                return firstItem.emitter;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) => item.emissionDate && item.emissionDate.getTime() === firstItem.emissionDate.getTime(),
                )
            ) {
                return firstItem.emissionDate;
            } else {
                return null;
            }
        },
        getSelectionCommonType(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.type);
            if (firstItem && selectedItems.every((item) => item.type === firstItem.type)) {
                return firstItem.type;
            } else {
                return null;
            }
        },
        getSelectionCommonCode(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.code);
            if (firstItem && selectedItems.every((item) => item.code === firstItem.code)) {
                return firstItem.code;
            } else {
                return null;
            }
        },
        getSelectionCommonSharedWithDeliveryContacts(selectedItems) {
            const firstItem = selectedItems.find((item) => item.sharedWithDeliveryContacts);
            if (
                firstItem &&
                selectedItems.every((item) => item.sharedWithDeliveryContacts === firstItem.sharedWithDeliveryContacts)
            ) {
                return firstItem.sharedWithDeliveryContacts
                    ? 'shared'
                    : firstItem.sharedWithDeliveryContacts === false
                    ? 'notShared'
                    : null;
            } else {
                return null;
            }
        },
        getSelectionCommonEmissionDueDate(selectedItems) {
            const firstItem = selectedItems.find((item) => !!item.emissionDueDate);
            if (
                firstItem &&
                selectedItems.every(
                    (item) =>
                        item.emissionDueDate && item.emissionDueDate.getTime() === firstItem.emissionDueDate.getTime(),
                )
            ) {
                return firstItem.emissionDueDate;
            } else {
                return null;
            }
        },
        toggleSelectAll() {
            if (this.selection.length < this.filteredItems.length) {
                this.selection = this.filteredItems.map((item) => item.id);
            } else {
                this.selection = [];
            }
            this.saveSelection();
        },
        cleanupSavedSelection() {
            this.selection = this.selection.filter((itemId) => !!this.items.find((anItem) => anItem.id === itemId));
        },
        saveSelection(item) {
            if (item) {
                if (!item.isSelected) {
                    this.selection.push(item.id);
                } else {
                    this.selection = this.selection.filter((anItem) => anItem !== item.id);
                }
            }
            localStorage.setItem('supports.selection.' + this.$route.params.projectId, JSON.stringify(this.selection));
        },
        restoreSelection() {
            const cache = localStorage.getItem('supports.selection.' + this.$route.params.projectId);
            if (cache) {
                this.selection = JSON.parse(cache);
            }
        },
        async addSupport() {
            const newSupport = await createSupport(this.$route.params.projectId, {
                name: '',
                type: 'graphic',
                emitterId: this.me.bundleIds[0],
            });
            await this.$router.push({
                name: 'support',
                params: {
                    projectId: this.$route.params.projectId,
                    supportId: newSupport.id,
                },
            });
        },
        isDone(item) {
            return item.emissionDate;
        },
        isFar(item) {
            const diff = differenceInWorkingDays(
                item.emissionDueDate,
                new Date(),
                this.calendar,
                item.bundle ? item.bundle.id : null,
            );
            return !item.emissionDate && diff > 1;
        },
        matchBundleFilter(item, bundleIds) {
            return item.emitter && bundleIds.includes(item.emitter.id);
        },
        matchNoEmitter(item) {
            return !item.emitter;
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString, criteria, true));
        },
        matchStatusFilter(item) {
            const isFar = this.isFar(item);
            const isDone = this.isDone(item);
            return (!isDone && !isFar && this.coming) || (isFar && this.far) || (isDone && this.done);
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'supports_filter_' + this.$route.params.projectId,
                JSON.stringify({
                    filterValue,
                    done: this.done,
                    coming: this.coming,
                    far: this.far,
                    sortKey: this.sortKey,
                    sortAsc: this.sortAsc,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('supports_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
                this.done = !!parsedCache.done;
                this.coming = !!parsedCache.coming;
                this.far = !!parsedCache.far;
                this.sortKey = parsedCache.sortKey;
                this.sortAsc = !!parsedCache.sortAsc;
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const locationCriteria = filter.filter((aCriteria) => aCriteria._isLocationCriteria);
            const typeCriteria = filter.filter((aCriteria) => aCriteria._isTypeCriteria).map((criteria) => criteria.id);
            const noEmitterCriteria = filter.filter((aCriteria) => aCriteria._isNoEmitterCriteria);
            const bundleCriteria = filter
                .filter((aCriteria) => aCriteria._isBundleCriteria)
                .map((criteria) => criteria.id);
            return this.items.filter((item) => {
                const fullCriteria = {
                    matchStatusFilter: this.matchStatusFilter(item),
                    matchLocationCriteria: locationService.matchLocationCriteria(locationCriteria, item.locations),
                    matchString: this.matchString(stringCriteria, item),
                    matchType: this.matchType(typeCriteria, item),
                    matchBundle: bundleCriteria.length === 0 || this.matchBundleFilter(item, bundleCriteria),
                    matchNoEmitterCriteria: noEmitterCriteria.length === 0 || this.matchNoEmitter(item),
                };
                const filterResult = Object.values(fullCriteria).every((value) => !!value);
                if (!filterResult) {
                    this.selection = this.selection.filter((id) => id !== item.id);
                }
                return filterResult;
            });
        },
        matchType(typeCriteria, item) {
            return typeCriteria.length === 0 || typeCriteria.includes(item.type);
        },
        updateEmissionDueDate(support, date) {
            updateSupport(this.$route.params.projectId, { id: support.id, emissionDueDate: date });
        },
        updateEmissionDate(support, date) {
            if (support.emissionDueDate) {
                updateSupport(this.$route.params.projectId, { id: support.id, emissionDate: date });
            } else {
                updateSupport(this.$route.params.projectId, {
                    id: support.id,
                    emissionDueDate: date,
                    emissionDate: date,
                });
            }
        },
        focusToNextDate(support) {
            let index = this.filteredItems.findIndex((aSupport) => aSupport.id === support.id);
            let nextSupport = this.filteredItems[index + 1];
            while (!nextSupport && index < this.filteredItems.length) {
                nextSupport = this.filteredItems[++index];
            }
            if (nextSupport) {
                this.$emit('select', nextSupport);
                const element = this.$refs['date_' + nextSupport.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
        focusToNextEmissionDueDate(support) {
            let index = this.filteredItems.findIndex((aSupport) => aSupport.id === support.id);
            let nextSupport = this.filteredItems[index + 1];
            while (!nextSupport && index < this.filteredItems.length) {
                nextSupport = this.filteredItems[++index];
            }
            if (nextSupport) {
                this.$emit('select', nextSupport);
                const element = this.$refs['emissionDueDate_' + nextSupport.id];
                if (element) {
                    if (Array.isArray(element)) {
                        element[0].focus();
                    } else {
                        element.focus();
                    }
                }
            }
        },
    },
    data() {
        return {
            prepareZip: false,
            loading: true,
            me: null,
            sortKey: 'name',
            sortAsc: true,
            readOnly: true,
            selection: [],
            editedSupport: {
                emissionDueDate: null,
                emissionDate: null,
                type: null,
                locations: [],
                service: null,
                emitter: null,
                code: null,
            },
            items: [],
            calendar: [],
            bundles: [],
            done: false,
            coming: true,
            far: false,
            filterValue: [],
            locationOptions: [],
            typeFilterOptions: [
                {
                    name: this.$t('supports.types.text'),
                    criteriaType: this.$t('supports.type'),
                    id: 'text',
                    _isTypeCriteria: true,
                },
                {
                    name: this.$t('supports.types.graphic'),
                    criteriaType: this.$t('supports.type'),
                    id: 'graphic',
                    _isTypeCriteria: true,
                },
                {
                    name: this.$t('supports.types.other'),
                    id: 'other',
                    criteriaType: this.$t('supports.type'),
                    _isTypeCriteria: true,
                },
            ],
            subscriptions: [],
        };
    },
};
</script>
