var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"min-h-toolbar text-white flex items-center bg-main px-2"},[(_vm.isMobile)?[(_vm.$route.params.projectId)?_c('nav',{staticClass:"flex flex-grow",attrs:{"aria-label":"Breadcrumb"}},[(_vm.isOpen)?_c('div',{staticClass:"w-full flex justify-between items-center"},[_c('router-link',{staticClass:"mx-2",attrs:{"to":{ name: 'home' },"aria-label":_vm.$t('home.title')}},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":"/logoTex_white.svg","alt":"TEX Logo"}})]),_c('app-sync-state')],1):[(_vm.$route.meta.backToMenu)?_c('button',{staticClass:"mr-4",attrs:{"aria-label":"toggle menu"},on:{"click":function($event){return _vm.toggleIsOpen()}}},[_c('icon-menu')],1):_c('router-link',{staticClass:"mr-4",attrs:{"to":{
                        name: _vm.$route.meta.parentName,
                        params: _vm.$route.params,
                    },"aria-label":"back"}},[_c('icon-chevron-left')],1),_c('h1',[_vm._v(_vm._s(_vm.getLastDefinedTitle()))])]],2):_c('nav',{staticClass:"w-full"},[_c('div',{staticClass:"flex w-full justify-between items-center gap-2"},[_c('router-link',{staticClass:"mx-2",attrs:{"to":{ name: 'home' },"aria-label":_vm.$t('home.title')}},[_c('img',{staticStyle:{"width":"60px"},attrs:{"src":"/logoTex_white.svg","alt":"TEX Logo"}})]),_c('div',{staticClass:"flex gap-2 items-center"},[_c('app-upgrade-button',[_c('span',{staticClass:"text-xs text-blue-400"},[_vm._v("v3.0.PATCH_VERSION")])]),(_vm.$route.name !== 'user')?_c('app-button',{attrs:{"icon":"icon-user","size":"mini"},on:{"click":function($event){return _vm.$router.push({ name: 'user' })}}}):_vm._e()],1)],1)])]:_vm._e(),(!_vm.isMobile)?[(!_vm.$route.meta.hideMenu)?_c('div',{},[_c('button',{staticClass:"m-1 -p2 rounded-full",attrs:{"aria-label":"toggle menu"},on:{"click":function($event){return _vm.toggleIsOpen()}}},[(!_vm.isOpen)?_c('icon-menu'):_vm._e(),(_vm.isOpen)?_c('icon-back-burger'):_vm._e()],1)]):_vm._e(),_c('nav',{staticClass:"flex flex-grow md:flex-grow-0 gap-2",attrs:{"aria-label":"Breadcrumb"}},[_vm._t("breadCrumbRoot"),_vm._l((_vm.$route.matched),function(crumb,index){return _c('div',[(index < _vm.$route.matched.length - 1)?_c('span',{staticClass:"hidden md:flex"},[(_vm.mapTitle(crumb.meta.title).length > 0)?_c('router-link',{staticClass:"mr-2",attrs:{"to":{ path: _vm.mapPath(crumb.path) }}},[_c('h1',{staticClass:"line-clamp-1"},[_vm._v(_vm._s(_vm.mapTitle(crumb.meta.title)))])]):_vm._e(),(_vm.mapTitle(crumb.meta.title).length > 0)?_c('icon-chevron-right'):_vm._e()],1):_vm._e(),(
                        index === _vm.$route.matched.length - 1 &&
                        _vm.mapTitle(crumb.meta.title) &&
                        _vm.mapTitle(crumb.meta.title).length > 0
                    )?[_c('h1',{staticClass:"md:line-clamp-1",class:{ hidden: _vm.isOpen }},[_vm._v("\n                        "+_vm._s(_vm.mapTitle(crumb.meta.title))+"\n                    ")]),_c('h1',{staticClass:"md:hidden",class:{ hidden: !_vm.isOpen }},[_vm._v("\n                        "+_vm._s(_vm.mapTitle(':projectName'))+"\n                    ")])]:_vm._e()],2)})],2),_c('div',{staticClass:"flex-grow hidden md:flex"}),(_vm.$route.params.projectId)?_c('app-sync-state',{staticClass:"offline:hidden hidden md:flex"}):_vm._e(),_c('icon-cloud-off-outline',{staticClass:"hidden offline:block text-yellow-400",attrs:{"width":"16","height":"16"}}),_c('user-dropdown',{staticClass:"hidden md:block"})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }