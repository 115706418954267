<template>
    <main class="p-2 md:p-5 w-full h-full max-h-main flex flex-col items-start min-h-main">
        <div class="w-full flex justify-center flex-col items-center">
            <app-multi-picker
                ref="filter"
                icon="icon-magnify"
                :allowStringCriteria="true"
                class="w-full my-2"
                v-model="filterValue"
                :options="filterOptions"
                :strictMatching="true"
            ></app-multi-picker>
            <div class="flex gap-8 text-xs">
                <div class="flex gap-2 items-center" :title="$t('project.editions.meetingReports.convocated')">
                    <icon-email-newsletter width="16" height="16"></icon-email-newsletter>
                    {{ convocatedCount }}
                </div>
                <div
                    class="flex gap-2 items-center"
                    :title="$t('project.editions.meetingReports.attendanceValuesPlural.present')"
                >
                    <icon-user class="text-green-600" width="16" height="16"></icon-user>
                    {{ presentCount }}
                </div>
                <div
                    class="flex gap-2 items-center"
                    :title="$t('project.editions.meetingReports.attendanceValuesPlural.excusedAbsent')"
                >
                    <icon-user class="text-yellow-600" width="16" height="16"></icon-user>
                    {{ excusedAbsentCount }}
                </div>
                <div
                    class="flex gap-2 items-center"
                    :title="$t('project.editions.meetingReports.attendanceValuesPlural.unexcusedAbsent')"
                >
                    <icon-user class="text-red-700" width="16" height="16"></icon-user>
                    {{ unexcusedAbsentCount }}
                </div>
                <div class="flex gap-2 items-center" :title="$t('project.editions.meetingReports.nextConvocation')">
                    {{ $t('project.editions.meetingReports.nextConvocation') }} :
                    <span v-if="nextConvocation">{{ nextConvocation.date | humanizeTime }}</span>
                    <span class="italic" v-else>{{ $t('project.editions.meetingReports.noNextConvocationYet') }}</span>
                </div>
            </div>
        </div>
        <div class="flex w-full flex-grow overflow-auto flex-col text-xs relative">
            <div v-if="loading" class="flex justify-center">
                <icon-rotate-right class="animate animate-spin"></icon-rotate-right>
            </div>
            <table class="table-fixed" v-else>
                <thead class="sticky top-0 bg-white">
                    <tr>
                        <th style="width: 6rem" class="text-left border-r p-1">
                            {{ $t('commons.date') }}
                        </th>
                        <th style="width: 3rem" class="text-center border-r p-1">
                            <span :title="$t('project.editions.meetingReports.convocated')">
                                {{ $t('project.editions.meetingReports.convocatedShort') }}
                            </span>
                        </th>
                        <th style="width: 3rem" class="text-center border-r p-1">
                            <span :title="$t('project.editions.meetingReports.attendance')">
                                {{ $t('project.editions.meetingReports.attendanceShort') }}
                            </span>
                        </th>
                        <th class="text-left border-r p-1 bg-white">
                            <span>{{ $t('commons.name') }}</span>
                        </th>
                        <th class="text-center border-r p-1 hidden sm:table-cell" style="width: 10rem">pdf</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="item in filteredItems">
                        <tr class="odd:bg-blue-50 border-t">
                            <td class="border-r p-1">
                                <span :class="{ 'italic text-gray-600': item.isPlanned }">
                                    {{ item.date | humanizeTime }}
                                </span>
                            </td>
                            <td class="border-r p-1">
                                <span class="flex justify-center">
                                    <icon-email-newsletter
                                        width="16"
                                        height="16"
                                        v-if="item.isConvocated"
                                    ></icon-email-newsletter>
                                </span>
                            </td>
                            <td class="border-r p-1 text-center">
                                <span class="flex justify-center" v-if="!item.isPlanned">
                                    <icon-user
                                        width="16"
                                        height="16"
                                        :class="{
                                            'text-gray-600':
                                                !item.isPresent && !item.isExcusedAbsent && !item.isUnexcusedAbsent,
                                            'text-green-600': item.isPresent,
                                            'text-yellow-600': item.isExcusedAbsent,
                                            'text-red-700': item.isUnexcusedAbsent,
                                        }"
                                    ></icon-user>
                                </span>
                            </td>
                            <td class="border-r p-1">
                                {{ item.name }}
                            </td>
                            <td class="border-r p-1">
                                <app-file-link
                                    class="text-xs"
                                    v-if="item.generationSucceeded && item.url"
                                    :fileName="item.fileName"
                                    :url="item.url"
                                ></app-file-link>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>
import { filterMatch, sortBy } from '@/services/sanitize.service';
import IconFileDocumentOutline from '@/icons/IconFileDocumentOutline';
import AppFileLink from '@/components/appFileLink/AppFileLink';
import { combineLatest } from 'rxjs';
import { getMeetings } from '@/features/meetings/meetings.service';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import IconEmailNewsletter from '@/icons/IconEmailNewsletter.vue';
import { getProject } from '@/features/projects/projects.service';

export default {
    components: {
        IconEmailNewsletter,
        AppMultiPicker,
        AppFileLink,
        IconFileDocumentOutline,
    },
    async created() {
        this.init();
    },
    computed: {
        convocatedCount() {
            return this.items.filter((item) => item.isConvocated && item.date < this.refDate).length;
        },
        presentCount() {
            return this.items.filter((item) => item.isPresent && item.date < this.refDate).length;
        },
        unexcusedAbsentCount() {
            return this.items.filter((item) => item.isUnexcusedAbsent && item.date < this.refDate).length;
        },
        excusedAbsentCount() {
            return this.items.filter((item) => item.isExcusedAbsent && item.date < this.refDate).length;
        },
        filteredItems() {
            return this.filterFn(this.filterValue);
        },
        filterOptions() {
            return [
                {
                    name: this.$t('project.editions.meetingReports.generatedReport'),
                    id: 'generatedReport',
                    criteriaType: '',
                },
                {
                    name: this.$t('project.editions.meetingReports.convocated'),
                    id: 'isConvocated',
                    criteriaType: '',
                },
                {
                    name: this.$t('project.editions.meetingReports.attendanceShortValues.present'),
                    id: 'isPresent',
                    criteriaType: '',
                },
                {
                    name: this.$t('project.editions.meetingReports.attendanceShortValues.excusedAbsent'),
                    id: 'isExcusedAbsent',
                    criteriaType: '',
                },
                {
                    name: this.$t('project.editions.meetingReports.attendanceShortValues.unexcusedAbsent'),
                    id: 'isUnexcusedAbsent',
                    criteriaType: '',
                },
            ];
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getMeetings(this.$route.params.projectId),
                    getProject(this.$route.params.projectId),
                ]).subscribe(([meetings, project]) => {
                    this.items = sortBy(meetings, 'date')
                        .reverse()
                        .map((meeting) => {
                            const convocations = meeting.convocations.filter((convocation) =>
                                project.me.bundleIds.includes(convocation.bundleId),
                            );
                            const isUnexcusedAbsent = convocations.some(
                                (convocation) => convocation.attendance === 'unexcusedAbsent',
                            );
                            const isExcusedAbsent =
                                !isUnexcusedAbsent &&
                                convocations.some((convocation) => convocation.attendance === 'excusedAbsent');
                            const isConvocated = convocations.length > 0;
                            return {
                                ...meeting,
                                isPresent:
                                    isConvocated &&
                                    convocations.every((convocation) => convocation.attendance === 'present'),
                                isConvocated,
                                isUnexcusedAbsent,
                                isExcusedAbsent,
                                isPlanned: meeting.date > this.refDate,
                            };
                        });
                    this.nextConvocation = this.items.find((item) => item.date > this.refDate && item.isConvocated);
                    this.loading = false;
                }),
            ];
        },
        matchString(stringCriteria, item) {
            if (!stringCriteria || stringCriteria.length === 0) {
                return true;
            }
            return stringCriteria.find((criteria) => filterMatch(item.filterString, criteria, true));
        },
        saveFilter(filterValue) {
            localStorage.setItem(
                'readOnlyMeetingReports_filter_' + this.$route.params.projectId,
                JSON.stringify({
                    filterValue,
                }),
            );
        },
        restoreFilter() {
            const cache = localStorage.getItem('readOnlyMeetingReports_filter_' + this.$route.params.projectId);
            if (cache) {
                const parsedCache = JSON.parse(cache);
                this.filterValue = parsedCache.filterValue || [];
            }
        },
        filterFn(filter) {
            this.saveFilter(filter);
            const stringCriteria = filter
                .filter((aCriteria) => aCriteria._isStringCriteria)
                .map((aCriteria) => aCriteria.content);
            const criteriaIds = filter.map((criteria) => criteria.id) || [];
            const generatedReport = criteriaIds.includes('generatedReport');
            const isConvocated = criteriaIds.includes('isConvocated');
            const isPresent = criteriaIds.includes('isPresent');
            const isExcusedAbsent = criteriaIds.includes('isExcusedAbsent');
            const isUnexcusedAbsent = criteriaIds.includes('isUnexcusedAbsent');

            return this.items.filter((item) => {
                const fullCriteria = {
                    generatedReport: item.url || !generatedReport,
                    isPresent: item.isPresent || !isPresent,
                    isConvocated: item.isConvocated || !isConvocated,
                    isExcusedAbsent: item.isExcusedAbsent || !isExcusedAbsent,
                    isUnexcusedAbsent: item.isUnexcusedAbsent || !isUnexcusedAbsent,
                    matchString: this.matchString(stringCriteria, item),
                };
                return Object.values(fullCriteria).every((value) => !!value);
            });
        },
    },
    data() {
        return {
            loading: true,
            items: [],
            filterValue: [],
            refDate: new Date(),
            nextConvocation: null,
        };
    },
};
</script>
