<template>
    <div class="flex p-4 flex-col gap-4">
        {{ $t('projects.support') }}
        <div class="flex justify-center">
            <div class="flex flex-col justify-center items-left">
                <a
                    class="text-gray-700 hover:underline p-2 flex gap-2"
                    target="_blank"
                    :href="'mailto:support@tex.moe?subject=J\'ai%20besoin%20d\'aide&body=Bonjour,%0D%0A%0D%0AJe%20rencontre%20un%20problème%0D%0A%0D%0AJe voudrais ... %0D%0A mais ... %0D%0A%0D%0A Vous pouvez me contacter au 06... %0D%0A%0D%0AMerci'"
                >
                    <icon-email-outline></icon-email-outline>
                    support@tex.moe
                </a>
                <a class="text-gray-700 p-2 flex gap-2" href="tel://+33645296415">
                    <icon-phone></icon-phone>
                    06 45 29 64 15
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            window,
        };
    },
};
</script>
