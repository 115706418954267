<template>
    <main class="max-w-5xl m-5 mb-10 w-full">
        <ValidationObserver v-slot="{ invalid, errors, dirty }" ref="observer">
            <form class="grid grid-cols-2 mt-4 gap-4">
                <app-input-text
                    :label="$t('users.firstName')"
                    v-model="deliveryContact.firstName"
                    class="col-span-2 md:col-span-1"
                    @blur="save()"
                />
                <app-input-text
                    :label="$t('users.lastName')"
                    v-model="deliveryContact.lastName"
                    class="col-span-2 md:col-span-1"
                    @blur="save()"
                />
                <app-input-text
                    :label="$t('project.information.deliveryContacts.companyName')"
                    v-model="deliveryContact.companyName"
                    class="col-span-2 md:col-span-1"
                    @blur="save()"
                />
                <app-select
                    :label="$t('commons.type')"
                    v-model="deliveryContact.type"
                    class="col-span-2"
                    @input="save()"
                >
                    <option value="manager">
                        {{ $t('project.information.deliveryContacts.types.manager') }}
                    </option>
                    <option value="owner">
                        {{ $t('project.information.deliveryContacts.types.owner') }}
                    </option>
                    <option value="tenant">{{ $t('project.information.deliveryContacts.types.tenant') }}</option>
                    <option value="other">
                        {{ $t('project.information.deliveryContacts.types.other') }}
                    </option>
                </app-select>
                <app-input-text
                    :label="$t('users.email')"
                    type="email"
                    v-model="deliveryContact.email"
                    class="col-span-2"
                    @blur="save()"
                    @paste="onPasteMail"
                >
                    <template v-slot:tip>
                        <app-tips>{{ $t('project.information.deliveryContacts.emailTip') }}</app-tips>
                    </template>
                </app-input-text>
                <app-phone-input
                    :label="$t('users.phone')"
                    v-model="deliveryContact.phone"
                    class="col-span-2"
                    @blur="save()"
                />
                <app-checkbox
                    v-model="deliveryContact.isOccupant"
                    @input="save"
                    class="col-span-2"
                    :label="$t('project.information.deliveryContacts.isOccupant')"
                ></app-checkbox>
                <div class="flex flex-row items-center gap-2 col-span-2">
                    <app-checkbox
                        v-model="deliveryContact.isArchived"
                        @input="save"
                        :label="$t('project.information.deliveryContacts.isArchived')"
                    ></app-checkbox>
                    <app-tips>{{ $t('project.information.deliveryContacts.isArchivedTip') }}</app-tips>
                </div>
                <app-checkbox
                    v-model="deliveryContact.hasTexAccess"
                    @input="save"
                    class="col-span-2"
                    :label="$t('project.information.deliveryContacts.hasTexAccess')"
                ></app-checkbox>
                <app-multi-picker
                    v-model="deliveryContact.locations"
                    :options="zoneOptions"
                    :show-label="true"
                    :label="$t('zones.zones')"
                    :title="$t('zones.zones')"
                    label-key="fullName"
                    @input="save"
                    class="col-span-2"
                ></app-multi-picker>
            </form>
        </ValidationObserver>
        <app-quick-actions :options="quickActions" @choose="$event.run()"></app-quick-actions>
    </main>
</template>

<script>
import { getMapById } from '@/services/sanitize.service';
import { combineLatest } from 'rxjs';
import {
    createDeliveryContact,
    getDeliveryContact,
    updateDeliveryContact,
} from '@/features/deliveryContacts/deliveryContacts.service';
import AppInputText from '@/components/appInputText/AppInputText.vue';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import AppMultiPicker from '@/components/appMultiPicker/AppMultiPicker.vue';
import AppFieldset from '@/components/appFieldset/AppFieldset.vue';
import AppTextEditor from '@/components/appTextEditor/AppTextEditor.vue';
import AppTips from '@/components/app-tips/AppTips.vue';
import AppButton from '@/components/appButton/AppButton.vue';
import { updateBreadCrumbs } from '@/state/state';
import AppQuickActions from '@/components/appQuickActions/AppQuickActions.vue';
import { getLocationsTree } from '@/features/locations/locations.service';
import AppCheckbox from '@/components/app-checkbox/AppCheckbox.vue';
import AppPhoneInput from '@/components/appPhoneInput/AppPhoneInput.vue';

export default {
    components: {
        AppPhoneInput,
        AppCheckbox,
        AppQuickActions,
        AppButton,
        AppTips,
        AppTextEditor,
        AppFieldset,
        AppMultiPicker,
        AppSelect,
        AppInputText,
    },
    created() {
        updateBreadCrumbs({ deliveryContactName: '' });
        this.init();
    },
    watch: {
        $route: function () {
            this.init();
        },
    },
    methods: {
        init() {
            this.subscriptions = [
                combineLatest([
                    getDeliveryContact(this.$route.params.projectId, this.$route.params.deliveryContactId),
                    getLocationsTree(this.$route.params.projectId),
                ]).subscribe(([deliveryContact, folders]) => {
                    const zones = folders
                        .reduce((acc, folder) => [...acc, ...folder.locations], [])
                        .reduce((acc, location) => [...acc, ...location.zones], []);
                    const zoneMap = getMapById(zones);
                    updateBreadCrumbs({
                        deliveryContactName:
                            (deliveryContact.companyName ? deliveryContact.companyName + ' - ' : '') +
                            (deliveryContact.firstName ? deliveryContact.firstName + ' ' : '') +
                            (deliveryContact.lastName ? deliveryContact.lastName : ''),
                    });
                    this.deliveryContact = {
                        ...deliveryContact,
                        locations: deliveryContact.locationIds.map((id) => zoneMap[id]).filter((a) => !!a),
                    };
                    this.zoneOptions = zones;
                }),
            ];
        },
        onPasteMail() {
            setTimeout(() => {
                this.deliveryContact.email = this.deliveryContact.email
                    ? this.deliveryContact.email.replace('mailto:', '').replaceAll(' ', '')
                    : '';
            }, 100);
        },
        async save() {
            return updateDeliveryContact(this.$route.params.projectId, {
                firstName: this.deliveryContact.firstName,
                lastName: this.deliveryContact.lastName,
                companyName: this.deliveryContact.companyName,
                phone: this.deliveryContact.phone,
                email: this.deliveryContact.email,
                type: this.deliveryContact.type,
                isOccupant: this.deliveryContact.isOccupant,
                isArchived: this.deliveryContact.isArchived,
                hasTexAccess: this.deliveryContact.hasTexAccess,
                locationIds: this.deliveryContact.locations.map((location) => location.id),
                id: this.$route.params.deliveryContactId,
            });
        },
    },
    data() {
        return {
            subscriptions: [],
            zoneOptions: [],
            quickActions: [
                {
                    name: this.$t('commons.new'),
                    run: async () => {
                        const result = await createDeliveryContact(this.$route.params.projectId, {
                            firstName: '',
                            type: 'owner',
                            locationIds: [],
                            isOccupant: false,
                            hasTexAccess: false,
                            isArchived: false,
                        });
                        await this.$router.push({
                            name: 'deliveryContact',
                            params: {
                                projectId: this.$route.params.projectId,
                                deliveryContactId: result.id,
                            },
                        });
                    },
                },
            ],
            deliveryContact: {
                firstName: '',
                lastName: '',
                type: 'owner',
                phone: '',
                email: '',
                locationIds: [],
                locations: [],
                isOccupant: true,
                hasTexAccess: false,
                isArchived: false,
            },
        };
    },
};
</script>
